import { useMutation, useQuery, useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useStores } from '../state';
import URLAssembler from 'url-assembler';
import {
  ServerResponseType,
  Customer,
  APIendPoints,
  generalProp,
  Contract,
  User,
  BranchOfficesResponse,
  BranchOfficesResponseItem,
  VehiclesResp,
  Vehicle,
  IEmailsList,
  IDictionary,
  IDCheck,
  NotificationRes,
  NotificationListRes,
  IUserOwnerResponse,
  Statsresponse,
  BrandsRes,
  DefaultCostRes,
  ExternalCaptureProcess,
} from './types';

import config from '../config';

const APIUrl = config.apiURL;

const Query = () => {
  const { ui } = useStores();
  // const [updatedToken, setToken] = useState(undefined);
  const localStorageToken = window.localStorage.getItem('token') || undefined;
  // console.log('localStorageToken', localStorageToken);
  const parsedObje = localStorageToken
    ? JSON.parse(localStorageToken)
    : undefined;

  const token = ui.token ? ui.token : parsedObje;
  // console.log('token query', token);
  const apiClient = axios.create({
    baseURL: APIUrl,
    headers: {
      Accept: 'application/json',
      Authorization: token,
    },
    // withCredentials: true,
  });
  const getIDcheckData = async (
    page: number,
    filter?: string,
    sortBy?: string,
    perPage?: number,
    context?: string,
    status?: [{ value: String }],
    approval_state?: [{ value: String }],
  ) => {
    const url = URLAssembler(`${APIUrl}/id_checks`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'desc',
        per_page: perPage ? perPage : '15',
        context: context ? context : 'all',
        status: status ? status : undefined,
        approval_state: approval_state ? approval_state : undefined,
      })
      .toString();

    const { data } = await apiClient.get<ServerResponseType>(url);

    return data;
  };
  const getBranchOfficesData = async (
    page: number,
    filter?: string,
    sortBy?: string,
  ) => {
    const url = URLAssembler(`${APIUrl}/dealer/branch_offices`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'asc',
        per_page: '7',
      })
      .toString();

    const { data } = await apiClient.get<BranchOfficesResponse>(url);

    return data;
  };
  const getCSVfile = async () =>
    await apiClient.get(`${APIUrl}/vehicles/to_csv_file`);
  const getVehicles = async (
    page: number,
    filter?: string,
    sortBy?: string,
    per_page?: number,
  ) => {
    const url = URLAssembler(`${APIUrl}/vehicles`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'asc',
        per_page: per_page ? per_page : '50',
      })
      .toString();

    const { data } = await apiClient.get<VehiclesResp>(url);

    return data;
  };

  const getUsers = async (page: number, filter?: string, sortBy?: string) => {
    const url = URLAssembler(`${APIUrl}/users`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'asc',
        per_page: '7',
      })
      .toString();

    const { data } = await apiClient.get<User[]>(url);

    return data;
  };
  const getCustomers = async (
    page: number,
    filter?: string,
    sortBy?: string,
    per_page?: number,
  ) => {
    const url = URLAssembler(`${APIUrl}/customers`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'asc',
        per_page: per_page ? per_page : '7',
      })
      .toString();

    const { data } = await apiClient.get<Customer[]>(url);

    return data;
  };
  const getScanToMail = async (
    page: number,
    filter?: string,
    sortBy?: string,
    per_page?: number,
    context?: string,
  ) => {
    const url = URLAssembler(`${APIUrl}/scan_to_mail/emails`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'asc',
        per_page: per_page ? per_page : '15',
        context: context ? context : 'all',
      })
      .toString();

    const { data } = await apiClient.get<IEmailsList>(url);

    return data;
  };

  // const getContractInsureance = async (
  //   page: number,
  //   filter?: string,
  //   sortBy?: string,
  // ) => {
  //   const url = URLAssembler(`${APIUrl}/contracts/insurences`)
  //     .query({
  //       page: page,
  //       q: filter,
  //       sort_by: sortBy,
  //       sort_direction: 'asc',
  //       per_page: '50',
  //     })
  //     .toString();

  //   const { data } = await apiClient.get<IContractInsuranceResponse>(url);

  //   return data;
  // };
  const getPushMessagesList = async (
    page: number,
    filter?: string,
    sortBy?: string,
  ) => {
    const url = URLAssembler(`${APIUrl}/push_messages`)
      .query({
        page: page,
        q: filter,
        sort_by: sortBy,
        sort_direction: 'asc',
        per_page: '50',
      })
      .toString();

    const { data } = await apiClient.get<NotificationListRes>(url);

    return data;
  };
  const getDashboardStatistics = async (id: number) => {
    const { data } = await apiClient.get<Statsresponse>(
      `${APIUrl}/dealer/headquarters/${id}/stats`,
    );

    return data;
  };
  const getVehiclesById = async (id: number) => {
    const { data } = await apiClient.get<Vehicle>(`${APIUrl}/vehicles/${id}`);

    return data;
  };
  const getVehiclesReservations = async (id: number) => {
    const { data } = await apiClient.get<Vehicle>(
      `${APIUrl}/reservations?vehicle_id=${id}`,
    );

    return data;
  };

  const getCustomerById = async (id: number) => {
    const { data } = await apiClient.get<Customer>(`${APIUrl}/customers/${id}`);

    return data;
  };
  const getIDCheckDataById = async (id: number) => {
    const { data } = await apiClient.get<IDCheck>(`${APIUrl}/id_checks/${id}`);

    return data;
  };
  const getBranchOfficeByID = async (id: number) => {
    const { data } = await apiClient.get<BranchOfficesResponseItem>(
      `${APIUrl}/dealer/branch_offices/${id}`,
    );

    return data;
  };
  const getHeadquarteByID = async (id: number) => {
    const { data } = await apiClient.get<BranchOfficesResponseItem>(
      `${APIUrl}/dealer/headquarters/${id}`,
    );

    return data;
  };
  const getContractById = async (url: string) => {
    const { data } = await apiClient.get<Contract>(url);

    return data;
  };
  const getNewContractData = async (url: string) => {
    const { data } = await apiClient.get<Contract>(`${APIUrl}/${url}`);

    return data;
  };
  const getUserByID = async (id: number) => {
    const { data } = await apiClient.get<User>(`${APIUrl}/users/${id}`);

    return data;
  };
  const getDictionary = async () => {
    const { data } = await apiClient.get<IDictionary>(`${APIUrl}/dictionary`);

    return data;
  };
  const getUserOwner = async () => {
    const { data } = await apiClient.get<IUserOwnerResponse>(
      `${APIUrl}/users/user_owners`,
    );

    return data;
  };
  const getNotificationCount = async () => {
    const { data } = await apiClient.get<NotificationRes>(
      `${APIUrl}/id_checks/context_counts`,
    );

    return data;
  };

  //@ts-ignore
  const QueryRequest = ({ ...props }, fetcher: () => Promise<any>) => {
    const { key, page, enabled = true, retry = 1 } = props;

    return useQuery<any, Error>([key, page], fetcher, {
      keepPreviousData: page ? true : false,
      enabled: enabled,
      retry: retry,
    });
  };

  const InfinitQueryRequest = (
    { ...props },
    fetcher: (par: any) => Promise<any>,
  ) => {
    const { key } = props;

    return useInfiniteQuery<any, Error>([key], fetcher, {
      getPreviousPageParam: ({ pagination }) => {
        return pagination.total_pages - Number(pagination.page);
      },
      getNextPageParam: ({ pagination }) =>
        pagination.total_pages > pagination.page
          ? Number(pagination.page) + 1
          : undefined,
    });
  };
  const MutationRequest = (url: string) =>
    useMutation((postRequestData: any) => apiClient.post(url, postRequestData));

  const MutationRequestPut = (url: string) =>
    useMutation((postRequestData: any) => apiClient.put(url, postRequestData));

  const MutationRequestDelete = (url: string) =>
    useMutation((postRequestData: any) =>
      apiClient.delete(url, postRequestData),
    );

  const DeleteIdScan = () => useMutation((id: string) => apiClient.delete(id));
  const DeleteAvatar = () => useMutation((id: string) => apiClient.delete(id));
  const DeleteVehiclesorReservation = () =>
    useMutation((id: string) => apiClient.delete(id));
  const MutationRequestPatch = (url: string) =>
    useMutation((postRequestData: any) =>
      apiClient.patch(url, postRequestData),
    );
  const SubmitDataToserver = ({ url }: { url: string }) => {
    return MutationRequest(url);
  };
  const ReturnVehiclesR = ({ url }: { url: string }) => {
    return MutationRequestPatch(url);
  };

  const creatLegitimation = async ({ id }: { id: number }) => {
    try {
      await apiClient.post(`/id_checks/${id}/approve`);
    } catch (e) {
      console.log('efsf', e);
    }
  };

  const disCardLegitimation = async ({ id }: { id: number }) => {
    await apiClient.post(`/id_checks/${id}/discard`);
  };

  const submit2FacCode = async ({ code }: { code: number }) => {
    return apiClient.post(
      `/users/two_factor_auth_challenge_attempt?code=${code}`,
    );
  };
  const Loggin = ({ type }: generalProp) => {
    return MutationRequest(APIendPoints[type]);
  };
  // const Loggin2FacAuth = (url: string) => {
  //   return MutationRequest2Auth(url);
  // };

  const Logout = ({ type }: generalProp) =>
    MutationRequestDelete(APIendPoints[type]);

  const UpdateUserAvatar = ({ url }: { url: string }) => MutationRequest(url);
  const UpdateUserData = ({ url }: { url: string }) => MutationRequestPut(url);
  const FetchIDtableDate = ({ ...props }) => {
    const {
      key,
      page,
      searchQuery,
      sortBy,
      perPage,
      context,
      status,
      approval_state,
      enabled = true,
    } = props;

    return QueryRequest({ key, page, enabled }, () =>
      getIDcheckData(
        page,
        searchQuery,
        sortBy,
        perPage,
        context,
        status,
        approval_state,
      ),
    );
  };
  const FetchDictionarysData = ({ ...props }) => {
    const { key, page } = props;

    return QueryRequest({ key, page }, () => getDictionary());
  };
  const FetchBranchOfficesData = ({ ...props }) => {
    const { key, page, searchQuery, sortBy } = props;

    return QueryRequest({ key, page }, () =>
      getBranchOfficesData(page, searchQuery, sortBy),
    );
  };
  const FetchVehicles = ({ ...props }) => {
    const { key, page, searchQuery, sortBy, perPage } = props;

    return QueryRequest({ key, page }, () =>
      getVehicles(page, searchQuery, sortBy, perPage),
    );
  };
  const FetchDashboardStatistics = ({ ...props }) => {
    const { key, id } = props;

    return QueryRequest({ key }, () => getDashboardStatistics(id));
  };
  const FetchVehiclesById = ({ ...props }) => {
    const { key, id, enabled } = props;
    return QueryRequest({ key, enabled }, () => getVehiclesById(id));
  };
  const FetchReservationsByVehiclesId = ({ ...props }) => {
    const { key, id } = props;
    return QueryRequest({ key }, () => getVehiclesReservations(id));
  };

  const FetchUsersData = ({ ...props }) => {
    const { key, page, searchQuery, sortBy } = props;

    return QueryRequest({ key, page }, () =>
      getUsers(page, searchQuery, sortBy),
    );
  };
  const FetchContractDefaultCosts = ({ ...props }) => {
    const { key } = props;

    return QueryRequest({ key }, async () => {
      // const url = URLAssembler()
      //   .query({
      //     page: page,
      //     // q: searchQuery,
      //     // sort_by: sortBy,
      //     // sort_direction: 'asc',
      //     // per_page: perPage ? perPage : '30',
      //   })
      // .toString();

      const { data } = await apiClient.get<DefaultCostRes[]>(
        `${APIUrl}/contracts/default_costs_sets`,
      );

      return data;
    });
  };
  const FetchUScanToMainData = ({ ...props }) => {
    const { key, page, searchQuery, sortBy, per_page, context } = props;

    return QueryRequest({ key, page }, () =>
      getScanToMail(page, searchQuery, sortBy, per_page, context),
    );
  };
  const FetchUserOwnerData = ({ ...props }) => {
    const { key, page } = props;

    return QueryRequest({ key, page }, () => getUserOwner());
  };

  const FetchCustomerssData = ({ ...props }) => {
    const { key, page, searchQuery, sortBy, perPage } = props;

    return QueryRequest({ key, page }, () =>
      getCustomers(page, searchQuery, sortBy, perPage),
    );
  };
  const FetchVehiclesBrands = ({ ...props }) => {
    const { page, searchQuery, sortBy, perPage } = props;

    return async () => {
      const url = URLAssembler(`${APIUrl}/vehicle_brands`)
        .query({
          page: page,
          q: searchQuery,
          sort_by: sortBy,
          sort_direction: 'asc',
          per_page: perPage ? perPage : '30',
        })
        .toString();

      const { data } = await apiClient.get<BrandsRes[]>(url);

      return data;
    };
  };

  const FetchCarsContractDateRange = ({ ...props }) => {
    const { id, start_date, end_date } = props;

    return async () => {
      const url = URLAssembler(
        `${APIUrl}/vehicles/${id}/reservations_for_date_range`,
      )
        .query({
          start_date,
          end_date,
        })
        .toString();

      const { data } = await apiClient.get<any>(url);

      return data;
    };
  };

  const FetchSbContractValue = ({ ...props }) => {
    const { enabled, url, key = 'sb_contract' } = props;

    return QueryRequest({ key, enabled }, async () => {
      const { data } = await apiClient.get<any>(url);

      return data;
    });
  };
  // const FetchContractInsuranceData = ({ ...props }) => {
  //   const { key, page, searchQuery, sortBy } = props;

  //   return QueryRequest({ key, page }, () =>
  //     getContractInsureance(page, searchQuery, sortBy),
  //   );
  // };
  const FetchPushMessagesList = ({ ...props }) => {
    const { key, page, searchQuery, sortBy } = props;

    return QueryRequest({ key, page }, () =>
      getPushMessagesList(page, searchQuery, sortBy),
    );
  };
  const FetchPushMessagesListInfinit = ({ ...props }) => {
    const { key } = props;

    return InfinitQueryRequest({ key }, async ({ pageParam = 1 }) => {
      const url = URLAssembler(`${APIUrl}/push_messages`)
        .query({
          page: pageParam,
          per_page: '12',
          sort_direction: 'desc',
        })
        .toString();
      const { data } = await apiClient.get<NotificationListRes>(url);
      return data;
    });
  };
  const FetchBranchOfficeDataById = ({ ...props }) => {
    const { key, id, enabled = true } = props;
    return QueryRequest({ key, enabled }, () => getBranchOfficeByID(id));
  };
  const FetchHeadquarterDataById = ({ ...props }) => {
    const { key, id, enabled = true } = props;

    return QueryRequest({ key, enabled }, () => getHeadquarteByID(id));
  };
  const FetchCustomerDataById = ({ ...props }) => {
    const { key, id } = props;
    return QueryRequest({ key }, () => getCustomerById(id));
  };
  const FetchIDCheckDataById = ({ ...props }) => {
    const { key, id, enabled = true } = props;
    return QueryRequest({ key, enabled }, () => getIDCheckDataById(id));
  };
  const FetchUserDataById = ({ ...props }) => {
    const { key, id, enabled = true } = props;
    return QueryRequest({ key, enabled }, () => getUserByID(id));
  };
  const FetchNewContractData = ({ ...props }) => {
    const { key, url } = props;
    return QueryRequest({ key }, () => getNewContractData(url));
  };
  const FetchContractDataById = ({ ...props }) => {
    const { key, url, enabled } = props;
    return QueryRequest({ key, enabled }, () => getContractById(url));
  };
  const FetchLegtimationCount = ({ ...props }) => {
    const { key } = props;
    return QueryRequest({ key }, () => getNotificationCount());
  };
  const FetchExternalImageCaptureProcess = ({ ...props }) => {
    const { key, token, enabled = false } = props;

    return QueryRequest({ key, enabled }, async () => {
      const { data } = await apiClient.get<ExternalCaptureProcess[]>(
        `${APIUrl}/external_image_capturing_processes/${token}`,
      );

      return data;
    });
  };

  const ImportCarsFromSellerCsv = ({ ...props }) => {
    const { key, enabled = false } = props;

    return QueryRequest({ key, enabled }, async () => {
      const response = await apiClient.get<any>(
        `${APIUrl}/vehicles/import_from_eautoseller_csv`,
      );
      return response.status;
    });
  };
  const getContractCSVfile = async (url: string) =>
    await apiClient.get(`${APIUrl}/${url}`);

  return {
    FetchSbContractValue,
    getContractCSVfile,
    ImportCarsFromSellerCsv,
    submit2FacCode,
    Loggin,
    Logout,
    FetchIDtableDate,
    FetchIDCheckDataById,
    QueryRequest,
    FetchCustomerDataById,
    FetchContractDataById,
    FetchUserDataById,
    UpdateUserAvatar,
    UpdateUserData,
    FetchUsersData,
    FetchBranchOfficesData,
    FetchBranchOfficeDataById,
    FetchHeadquarterDataById,
    FetchVehicles,
    FetchVehiclesById,
    FetchReservationsByVehiclesId,
    SubmitDataToserver,
    FetchUScanToMainData,
    FetchDictionarysData,
    creatLegitimation,
    getCustomers,
    FetchCustomerssData,
    ReturnVehiclesR,
    disCardLegitimation,
    FetchLegtimationCount,
    DeleteIdScan,
    DeleteAvatar,
    FetchUserOwnerData,
    FetchPushMessagesList,
    FetchDashboardStatistics,
    getCSVfile,
    DeleteVehiclesorReservation,
    FetchNewContractData,
    FetchPushMessagesListInfinit,
    FetchVehiclesBrands,
    FetchContractDefaultCosts,
    FetchExternalImageCaptureProcess,
    FetchCarsContractDateRange,
  };
};
export default Query;
