import { CheckIcon } from '@heroicons/react/solid';
import { classNames } from '../../../../styles/utils';

type StepsProp = {
  id: number | string;
  name: string;
  description: string;
};

interface IStepProp extends StepsProp {
  openTab: number | string;
}
const Step = ({ name, id, description, openTab }: IStepProp) => {
  const status =
    id === openTab
      ? 'current'
      : id !== openTab && id < openTab
      ? 'previous'
      : 'next';

  const classsobject = {
    current: {
      icon:
        'w-10 h-10 flex items-center justify-center bg-orange-500 rounded-full',
      name: 'text-xs font-semibold text-orange-600 tracking-wide uppercase',
      id:
        'w-10 h-10 flex items-center justify-center border-2 border-orange-600 rounded-full text-orange-600',
    },
    previous: {
      icon:
        'w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full',
      name: 'text-xs font-semibold text-gray-800 tracking-wide uppercase',
      id: '',
    },
    next: {
      icon:
        'w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full',
      name: 'text-xs font-semibold text-gray-600 tracking-wide uppercase',
      id:
        'w-10 h-10 flex items-center justify-center border-2 border-gray-400 rounded-full text-gray-400',
    },
  };
  const selectClassObject = classsobject[status];
  // console.log('status of steps', status);
  // console.log('classsobject of steps', selectClassObject);
  return (
    <div className="group">
      <span
        className={classNames(
          status === 'current' ? 'border-b-4 border-orange-500 ' : '',
          'absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto',
        )}
        aria-hidden="true"
      />
      <span
        className={classNames(
          id !== 0 ? 'lg:pl-9' : '',
          'px-6 py-5 flex items-start text-sm font-medium',
        )}>
        {status === 'previous' ? (
          <span className="flex-shrink-0">
            <span className="w-10 h-10 flex items-center justify-center bg-orange-500 rounded-full">
              <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
            </span>
          </span>
        ) : (
          <span className={selectClassObject.id}>
            <span>{`0${Number(id) + 1}`}</span>
          </span>
        )}
        <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
          <span className={selectClassObject.name}>{name}</span>
          <span className="text-sm font-medium text-gray-500">
            {description}
          </span>
        </span>
      </span>

      {/* Separator */}
      <div
        className="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
        aria-hidden="true">
        <svg
          className="h-full w-full text-gray-300"
          viewBox="0 0 12 82"
          fill="none"
          preserveAspectRatio="none">
          <path
            d="M0.5 0V31L10.5 41L0.5 51V82"
            stroke="currentcolor"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
    </div>
  );
};

export default Step;
