import Step from './step';
import { classNames } from '../../../../../styles/utils';

const Steps = ({
  openTab,
  documentType,
}: {
  openTab: string | number;
  documentType: 'PASSPORT' | 'IDENTITY_CARD' | 'DRIVERS_LISCENSE';
}) => {
  let stepsObj;
  if (documentType === 'IDENTITY_CARD') {
    stepsObj = [
      {
        id: 0,
        name: 'Vorderseite Personalausweis',
        description: '',
      },
      {
        id: 1,
        name: 'Rückseite Personalausweis',
        description: 'Fahrzeug und Fahrzeugstatus',
      },

      {
        id: 2,
        name: 'Fertig',
        description: '',
      },
    ];
  } else {
    stepsObj = [
      {
        id: 0,
        name: 'Vorderseite Reisepass oder Führerschein',
        description: '',
      },

      {
        id: 1,
        name: 'Fertig',
        description: '',
      },
    ];
  }

  return (
    <div>
      <nav className="w-full" aria-label="Progress">
        <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">
          {stepsObj.map((step, stepIdx) => (
            <li
              key={step.id}
              className="relative overflow-hidden lg:flex-1"
              //  onClick={(e) => {
              //    e.preventDefault();
              //    setOpenTab(step.id);
              //  }}
            >
              <div
                className={classNames(
                  openTab === 0 ? 'border-b-0 rounded-t-md' : '',
                  openTab === stepsObj.length - 1
                    ? 'border-t-0 rounded-b-md'
                    : '',
                  'border border-gray-200 overflow-hidden lg:border-0',
                )}>
                {openTab === stepIdx ? (
                  <Step openTab={openTab} {...step} />
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Steps;
