/* eslint-disable class-methods-use-this */
import { action, observable } from 'mobx';

/* {
keeping MObx implimentation for now just to compare how to do stuff 
and check the performance 
  
}
*/
type sortedValueType = {
  id: number;
  name: string;
  value: string;
};
class UIStore {
  @observable token: string | null = null;

  @action setToken(token: string) {
    this.token = token;
  }
}

export default UIStore;
