import {
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  matchPath,
  Link,
} from 'react-router-dom';
import { User } from 'shared';
import IDcheckHeader from './header';
import Main from './main';
import UserDetailspage from './legitimation_details';
import NewLegitimation from './new_legitimation';
import NewContract from './new_contract/checktastic_contract';
import NewSBContract from './new_contract/sb_contract';
import ContractDetails from './contract_details';
import SBContractDetails from './sb_contract_details';

const stepBasedOnSubscriptionType = {
  contractPremium: [
    {
      id: 0,
      name: 'Persönliche Daten',
      description: 'Name, Kontaktdaten, ...',
      title: 'Persönliche Daten',
      subTitle: 'Geben Sie hier die persönlichen Daten des Kunden ein.',
    },
    {
      id: 1,
      name: 'Fahrzeug',
      description: 'Fahrzeug und Fahrzeugstatus',
      title: 'Fahrzeug auswählen',
      subTitle: (
        <>
          Wählen Sie hier das Fahrzeug aus und kontrollieren Sie vorab den
          KM-Stand sowie eventuelle Schäden. Bitte beachten Sie, dass Sie das
          Fahrzeug vorab in den
          <Link to="/setting_page" target={'_blank'}>
            <span className="font-bold px-1 text-orange-500">Bestand</span>
          </Link>
          laden müssen.
        </>
      ),
    },
    {
      id: 2,
      name: 'weitere Vertragsdaten',
      description: 'Kosten, Versicherung, ...',
      title: 'Weitere Vertragsdaten',
      subTitle: 'Tragen Sie hier Ihre Rahmenbedingungen für den Vertrag ein',
    },
    {
      id: 3,
      name: 'Vorschau',
      description: 'Alles im Überblick',
      title: 'Alle Informationen überprüfen',
      subTitle:
        'Hier finden Sie alle Eingaben im Überblick. Für Änderungswünsche können Sie einfach zurück gehen',
    },
  ],
  premumWithSBInsurance: [
    {
      id: 0,
      name: 'Persönliche Daten',
      description: 'Name, Kontaktdaten, ...',
      title: 'Persönliche Daten',
      subTitle: 'Geben Sie hier die persönlichen Daten des Kunden ein.',
    },
    {
      id: 1,
      name: 'Fahrzeug',
      description: 'Fahrzeug und Fahrzeugstatus',
      title: 'Fahrzeug auswählen',
      subTitle: (
        <>
          Wählen Sie hier das Fahrzeug aus und kontrollieren Sie vorab den
          KM-Stand sowie eventuelle Schäden. Bitte beachten Sie, dass Sie das
          Fahrzeug vorab in den
          <Link to="/setting_page" target={'_blank'}>
            <span className="font-bold px-1 text-orange-500">Bestand</span>
          </Link>
          laden müssen.
        </>
      ),
    },
    {
      id: 2,
      name: 'weitere Vertragsdaten',
      description: 'Kosten, Versicherung, ...',
      title: 'Weitere Vertragsdaten',
      subTitle: 'Tragen Sie hier Ihre Rahmenbedingungen für den Vertrag ein',
    },
    {
      id: 3,
      name: 'Versicherung',
      description: 'Selbstbeteiligung',
      title: 'Selbstbeteiligungschutz buchen?',
      subTitle: '',
    },
    {
      id: 4,
      name: 'Vorschau',
      description: 'Alles im Überblick',
      title: 'Alle Informationen überprüfen',
      subTitle:
        'Hier finden Sie alle Eingaben im Überblick. Für Änderungswünsche können Sie einfach zurück gehen',
    },
  ],
  lightWithSBInsurance: [
    {
      id: 0,
      name: 'Persönliche Daten',
      description: 'Name, Kontaktdaten, ...',
      title: 'Persönliche Daten',
      subTitle: 'Geben Sie hier die persönlichen Daten des Kunden ein.',
    },

    {
      id: 1,
      name: 'Versicherung',
      description: 'Selbstbeteiligung',
      title: 'Versicherung',
      subTitle:
        ' Bitte wählen Sie kostenfrei, oder entscheiden Sie auf Tages-oderStundenbasis. Die Kosten werden im nächsten Schritt zu einerÜbersicht',
    },
    {
      id: 2,
      name: 'Vorschau',
      description: 'Alles im Überblick',
      title: 'Alle Informationen überprüfen',
      subTitle:
        'Hier finden Sie alle Eingaben im Überblick. Für Änderungswünsche können Sie einfach zurück gehen',
    },
  ],
};
const getContractType = (
  isSecure: boolean,
  uses_sb_insurance: boolean,
  isLightProduct: boolean,
  plan_version: string,
) => {
  if (isSecure && uses_sb_insurance && plan_version === 'full') {
    return 'premumWithSBInsurance';
  }
  // this next line for adding SB insurance to light premium ( as an extra contract)
  if (isSecure && uses_sb_insurance && plan_version === 'light') {
    return 'lightWithSBInsurance';
  }
  if (isLightProduct && uses_sb_insurance) {
    return 'lightWithSBInsurance';
  }

  return 'contractPremium';
};
const CheckIdPage = ({
  user_owner_type,
  user_owner_id,
  plan_type,
  plan_version,
  extend_with_drivers_license = true,
  uses_sb_insurance,
}: User) => {
  // type?: 'contractPremium' | 'lightWithSBInsurance' | 'premumWithSBInsurance';
  let { path } = useRouteMatch();
  let location = useLocation();
  const isLightProduct = plan_version === 'light';
  const matchobject = matchPath(location.pathname, {
    path: isLightProduct ? `${path}/new_sb_contract` : `${path}/new_contract`,
  });
  const isSecure = plan_type === 'premium';
  // contractType here should come from the backend
  let contractType:
    | 'contractPremium'
    | 'lightWithSBInsurance'
    | 'premumWithSBInsurance' = getContractType(
    isSecure,
    uses_sb_insurance,
    isLightProduct,
    plan_version,
  );
  let contractConfigObject = stepBasedOnSubscriptionType[contractType];
  const isJustIdCheck =
    isLightProduct && !isSecure && !extend_with_drivers_license;

  // console.log('plan_version', plan_version, contractType);
  // console.log('plan_type', plan_type, uses_sb_insurance);

  return (
    <div className="h-full p-5 bg-gray-100">
      {!matchobject ? <IDcheckHeader contractType={contractType} /> : null}
      <Switch>
        <Route exact path={path}>
          <Main contractType={contractType} />
        </Route>
        <Route exact path={`${path}/new_legitimation`}>
          <NewLegitimation
            isJustIdCheck={isJustIdCheck}
            userOwnerType={user_owner_type}
            userOwnerId={user_owner_id}
          />
        </Route>
        <Route exact path={`${path}/new_contract/:id`}>
          <NewContract
            contractConfig={contractConfigObject}
            hasSBInsurance={contractType === 'premumWithSBInsurance'}
          />
        </Route>
        <Route exact path={`${path}/contract_details/:id`}>
          <ContractDetails />
        </Route>
        <Route exact path={`${path}/new_sb_contract/:id`}>
          <NewSBContract
            contractConfig={contractConfigObject}
            contractType={contractType}
          />
        </Route>
        <Route exact path={`${path}/sb_contract_details/:id`}>
          <SBContractDetails />
        </Route>
        <Route path={`${path}/:customerId`}>
          <UserDetailspage />
        </Route>
      </Switch>
    </div>
  );
};

export default CheckIdPage;
