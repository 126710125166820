import { useState, Fragment, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { MenuAlt2Icon, BellIcon } from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import { Query } from 'shared';
import { RenderThumb } from '../screens/admin/file_upload';
import { onMessageListener } from '../../firebaseInit';
import { useAuthContext } from '../../hooks';
import { SideItems } from './sidebar';
import { Button, Nav, Slideover } from '../../styles/components';
import { classNames } from '../../styles/utils';
import NotificationCenter from '../screens/notifications';
import useStore from '../../ui-state/ui';
import { UserIcon } from '@heroicons/react/solid';
import { Modal } from '../shared';

type HeaderProps = {
  isMobile?: string;
};
const userNavigation = [
  { name: 'Einstellungen', href: '/setting_page' },
  { name: 'Hilfe und Kontakt', href: '/privacy' },
];

const Header: React.FC<HeaderProps> = () => {
  const setFetchIdCheckTableDataOnNewLegitimationUpdate = useStore(
    (state) => state.setFetchIdCheckTableDataOnNewLegitimationUpdate,
  );
  const [isNewNotification, setNewNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [query, setSerachQuery] = useState('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { FetchUserDataById } = Query();
  const [tokenModal, setTokenModalOpen] = useState(false);
  // check the loggin page line 82 note for more info
  const setUserToStorage = useStore((state) => state.setUserToStorage);

  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const isLightProduct = user ? user.plan_version === 'light' : false;
  const history = useHistory();

  const { url } = useRouteMatch();
  let location = useLocation();

  const hideSearchHeader =
    location.pathname.includes('/id_check/new_contract/') ||
    location.pathname === '/id_check/new_legitimation' ||
    location.pathname.includes('/id_check/new_sb_contract');
  const id = user ? user.id : undefined;
  const {
    data,
    isSuccess,
    error: fetchUserErro,
  } = FetchUserDataById({
    key: `user-${id}`,
    id: id,
    enabled: !!id,
  });

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const query = (target as HTMLInputElement).value;
    setSerachQuery(query);
  };
  const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      history.push(`/search?query=${query}`);
      setSerachQuery('');
    }
  };
  const handlelogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUserToStorage(false);
    auth.signout();
  };
  const [message, setFirebaseMessage] = useState<any>();

  useEffect(() => {
    //@ts-ignore
    if (
      fetchUserErro &&
      //@ts-ignore
      fetchUserErro.response &&
      //@ts-ignore
      fetchUserErro.response.status === 401
    ) {
      setTokenModalOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserErro]);

  onMessageListener()
    .then((payload) => {
      //@ts-ignore
      const { data } = payload;
      const { body, title, icon, type } = data;

      setFirebaseMessage({
        id: 1,
        content: body,
        target: title,
        href: icon,
        date: 'Sep 20',
        datetime: new Date().toUTCString(),
        icon: UserIcon,
        iconBackground: 'bg-gray-400',
      });
      setFetchIdCheckTableDataOnNewLegitimationUpdate(type === 'new_id_check');
      setNewNotification(true);
    })
    .catch((err) => {
      console.log('err', err);
    });
  return (
    <header className="w-full">
      <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
        <Button
          onClick={() => setMobileMenuOpen(true)}
          startIcon={<MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />}
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500 md:hidden"
        />
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex">
            {!hideSearchHeader ? (
              <div className="w-full flex md:ml-0">
                <label htmlFor="search_field" className="sr-only">
                  Search all files
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <SearchIcon
                      className="flex-shrink-0 h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    value={query}
                    onKeyPress={handleKeyDown}
                    onChange={handleChange}
                    name="search_field"
                    id="search_field"
                    className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                    placeholder={
                      !isLightProduct
                        ? 'Suche nach Name, Fahrzeug ...'
                        : 'Suche nach Name ...'
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <span className="inline-block relative">
              <Button
                onClick={() => {
                  setOpen(!open);
                  setNewNotification(false);
                  setFetchIdCheckTableDataOnNewLegitimationUpdate(false);
                }}
                startIcon={<BellIcon className="h-6 w-6" aria-hidden="true" />}
                className="mx-1 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400"
              />
              {isNewNotification && (
                <span className="absolute top-1 right-2 block h-2 w-2 rounded-full ring-2 ring-white bg-red-400" />
              )}
            </span>
            {/* Profile dropdown */}
            <Menu as="div" className="relative flex-shrink-0">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400">
                      <span className="sr-only">Open user menu</span>
                      <RenderThumb
                        avatarClassName="h-9 w-9 rounded-full"
                        file={
                          isSuccess ? data.avatar_image.thumbnail : undefined
                        }
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Nav.Item
                              label={item.name}
                              to={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100',
                              )}
                            />
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        {({ active }) => (
                          <Button
                            onClick={handlelogout}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full justify-start flex',
                            )}
                            label="Abmelden"
                          />
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
      <Slideover open={open} toggle={setOpen} title="Mitteilungen">
        <NotificationCenter message={message} />
      </Slideover>
      <Slideover
        open={mobileMenuOpen}
        toggle={setMobileMenuOpen}
        style={{
          headerStyle: 'bg-sidebar',
          bodyStyle: 'bg-sidebar',
        }}
        title={
          <div className="flex-shrink-0 px-5 flex items-center">
            <RenderThumb
              avatarClassName="h-8 w-auto"
              file={isSuccess ? data.avatar_image.thumbnail : undefined}
            />
          </div>
        }>
        <div
          className="mt-5 flex-1 flex flex-grow"
          // onClick={() => toggle(false)}
        >
          <SideItems
            verticalitems={false}
            onSideItemCLick={() => setMobileMenuOpen(false)}
          />
        </div>
      </Slideover>
      <Modal
        setOpen={setTokenModalOpen}
        open={tokenModal}
        title="Ihre Sicherheit ist uns wichtig!"
        subtile="Ihre Zwei-Faktor-Authentifizierung (2FA) ist abgelaufen oder Ihre Sitzung wurde beendet. Bitte melden Sie sich erneut an, um fortzufahren.">
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              handlelogout();
              window.location.reload();
              history.push(`${url}/login`);
              console.log('click ok on token modal');
            }}
            label="Jetzt anmelden"
            type="primary"
            className="w-full justify-center "
          />
        </div>
      </Modal>
    </header>
  );
};

export default Header;
