import { useEffect, useState } from 'react';
import { MailIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { User, Query, Statsresponse, Stats } from 'shared';
import { IdCheck } from '../../../styles/icons';
import {
  FetchHandlerWrapper,
  Modal,
  SearchSortHeader,
  Tabs,
  currentTabProp,
} from '../../shared';
// import { rows, statusStyles } from '../ID_check/utils';
import {
  // TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableData,
  SelectList,
  Button,
} from '../../../styles/components';
import { useAuthContext } from '../../../hooks';
// import { classNames } from '../../../styles/utils';
// import { useAuthContext } from '../../../hooks';
import useStore from '../../../ui-state/ui';
import moment from 'moment';

const columnsUserDetailsPage = [
  'Name',
  'Standort',
  'Strasse',
  'Aktueller Monat',
  'Aktuelles Jahr',
];
const tabs = [
  {
    name: 'Akzeptierte Legitimationen',
    status: [
      'accepted_id_checks_yearly_count',
      'accepted_id_checks_monthly_count',
      'accepted_id_checks_last_six_month_splitted',
    ],
    id: 2,
    hasCounter: false,
  },
  {
    name: 'Abgelehnte Legitimationen',
    status: [
      'declined_id_checks_yearly_count',
      'declined_id_checks_monthly_count',
      'declined_id_checks_last_six_month_splitted_count',
    ],
    id: 3,
    hasCounter: false,
  },
];
export const memberPageSortSelectObject = [
  // { name: 'Alle', status: '', id: 0 },
  {
    name: 'Name',
    status: 'name',
    id: 2,
  },
  { name: 'Standort', status: 'address', id: 1 },
];

const sortTypes = {
  address: (a: any, b: any) =>
    a.branch_office.address > b.branch_office.address ? 1 : -1,
  name: (a: any, b: any) =>
    b.branch_office.name > a.branch_office.name ? 1 : -1,
  default: {
    fn: (a: any, b: any) => a,
  },
};

const Dashboard = ({
  user_owner_type,
  user_owner_id,
  id,
  surname,
  prename,
}: User) => {
  const [query, setSearchQuery] = useState('');
  const [selectedMonth, setSelectedMonth] = useState<{
    name: string;
    status?: string;
    id: string;
  }>();
  const [selected, setSelected] = useState<{
    name: string;
    status: string;
    id: number;
  }>();
  const auth = useAuthContext();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [tokenModal, setTokenModalOpen] = useState(false);

  const [current, setCurrent] = useState<currentTabProp>({
    status: [
      'contracts_with_vehicle_returned_yearly_count',
      'contracts_with_vehicle_returned_monthly_count',
      'contracts_with_vehicle_returned_last_six_month_splitted_count',
    ],
    id: auth.user && auth.user.plan_version === 'light' ? 2 : 0,
  });

  const isHeadQuarter = user_owner_type === 'Dealer::Headquarter';
  const handleTabSelect = (selectedTabItem: currentTabProp) => {
    setCurrent(selectedTabItem);
  };
  const setUserToStorage = useStore((state) => state.setUserToStorage);
  const setCurrentIdCheckTableTabs = useStore(
    (state) => state.setCurrentIdCheckTableTabs,
  );
  const getLast6Month = () => {
    // let months = moment.months().map((month, index) => ({
    //   name: month,
    //   id: index,
    //   status: month.toLowerCase(),
    // }));
    // let currentMonth = Number(moment().format('M'));
    // const last6 = months.slice(0, currentMonth).slice(currentMonth - 6);
    var previousSixMonths = (function () {
      let n = 0;
      let arRet = [];

      for (; n < 6; n++)
        arRet.push(moment().subtract(n, 'months').format('MMMM'));
      return arRet.map((month, index) => ({
        name: month,
        id: index,
        status: month.toLowerCase(),
      }));
    })();

    // console.log('previousSixMonths', previousSixMonths);

    const last6MonthWithZeroBasedIndex = previousSixMonths.map(
      (month, index) => ({
        ...month,
        id: index,
      }),
    );
    //last6MonthWithZeroBasedIndex to be able to filter api data becasue it comes sorted based on array of numbes [0,4,12,3,2,0]
    return last6MonthWithZeroBasedIndex;
  };

  // const [selected, setSelected] = useState();
  const {
    FetchLegtimationCount,
    FetchDashboardStatistics,
    FetchBranchOfficeDataById,
    FetchHeadquarterDataById,
  } = Query();
  const {
    data,
    error,
    isError: isCountError,
  } = FetchLegtimationCount({
    key: 'count',
  });

  const cards = [
    {
      name: 'Alle Vorgänge',
      href: '/id_check',
      icon: MailIcon,
      amount: data ? data.all : '',
      bg: 'bg-orange-500',
      onClick: () =>
        setCurrentIdCheckTableTabs({
          status: 'all',
          id: 0,
        }),
    },
    {
      name: 'Offene Vorgänge',
      href: '/id_check',
      icon: ArrowUpIcon,
      amount: data ? data.active : '',
      bg: 'bg-green-400',
      onClick: () =>
        setCurrentIdCheckTableTabs({
          status: 'new',
          id: 2,
        }),
    },
  ];

  const {
    data: statistics,
    isError,
    isLoading,
    // error: staticticError,
  } = FetchDashboardStatistics({
    key: `statistics-${id}`,
    id: id,
  });

  const {
    data: headQuarterData,
    error: headQuarterError,
    isError: isHeadQuarterError,
  } = FetchHeadquarterDataById({
    key: 'head_quarter',
    id: user_owner_id,
    enabled: isHeadQuarter && user_owner_id !== undefined,
  });

  const {
    data: branchOfficeData,
    error: isBranchOfficeError,
    isError: isBranchError,
  } = FetchBranchOfficeDataById({
    key: 'branch_office',
    id: user_owner_id,
    enabled: !isHeadQuarter && user_owner_id !== undefined,
  });

  useEffect(() => {
    if (
      //@ts-ignore
      (error && error.response && error.response.status === 401) ||
      (headQuarterError &&
        //@ts-ignore
        headQuarterError.response &&
        //@ts-ignore
        headQuarterError.response.status === 401) ||
      (isBranchOfficeError &&
        //@ts-ignore
        isBranchOfficeError.response &&
        //@ts-ignore
        isBranchOfficeError.response.status === 401)
    ) {
      setTokenModalOpen(true);
      // localStorage.removeItem('user');
      // localStorage.removeItem('token');
      // setUserToStorage(false);
      // auth.signout();
      // window.location.reload();
    }
  }, [
    isCountError,
    isError,
    isBranchError,
    isHeadQuarterError,
    setUserToStorage,
    auth,
    error,
    headQuarterError,
    isBranchOfficeError,
  ]);

  /*{
getResultsBadedOnOpenedTab used to render results for dashboard but disable based on cliend requirement based on the ticket 
https://gitlab.voidmod.com/24relations/web_mobile_client/-/issues/250

  }*/
  // const getResultsBadedOnOpenedTab = () => {
  //   if (!statistics) return undefined;

  //   switch (current.id) {
  //     //  'Akzeptierte Legitimationen' count
  //     case 2:
  //       return `${statistics.stats.accepted_id_checks_yearly_count} Ergebnisse`;

  //     //  'Abgelehnte Legitimationen' count
  //     case 3:
  //       return `${statistics.stats.declined_id_checks_yearly_count} Ergebnisse`;

  //     //   'Abgeschlossene Vorgänge' count
  //     default:
  //       return `${statistics.stats.discarded_id_checks_yearly_count} Ergebnisse`;
  //   }
  // };
  const currentOfficeData = isHeadQuarter ? headQuarterData : branchOfficeData;
  const isLightProduct = currentOfficeData
    ? currentOfficeData.is_plan_version_light
    : false;
  //  console.log('user', user);
  // console.log('current', current);
  // console.log('statistics', statistics);
  if (!currentOfficeData || !currentOfficeData) return null;
  return (
    <div className="px-6">
      <div className="py-4">
        {/* <p className="text-gray-400">Startseite</p> */}
        <div className="py-4">
          <h2 className="text-3xl leading-6 font-extrabold text-gray-900 ">
            {`Willkommen ${prename} ${surname}`}
          </h2>
          <p className="text-gray-900 mt-2 text-lg">Schön, dass Sie da sind.</p>
        </div>
        <div className="mt-2 grid grid-cols-1 gap-5 lg:grid-cols-3">
          {/* Card */}
          {!isLightProduct
            ? cards.map((card) => (
                <div
                  key={card.name}
                  className="bg-white overflow-hidden shadow rounded-xl">
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className={`flex-shrink-0 p-3 ${card.bg}`}>
                        <card.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">
                            {card.name}
                          </dt>
                          <dd>
                            <div className="text-lg font-extrabold text-gray-900">
                              {card.amount}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-5 py-3">
                    <div className="text-sm" onClick={card.onClick}>
                      <Link
                        to={card.href}
                        className="font-bold text-orange-500 hover:text-gray-900">
                        Vorgänge ansehen
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            : null}
          <div className="bg-orange-500 overflow-hidden shadow rounded-lg  flex flex-col justify-center">
            <Link to={'/id_check/new_legitimation'}>
              <div className="flex items-center p-5">
                <div className="flex-shrink-0 p-6">
                  <IdCheck className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div className="ml-5 w-0 flex-1 text-2xl text-white font-bold">
                  <h2>ID Check starten</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* table section */}
      <div className="mt-5 flex flex-col">
        <Tabs
          tabs={
            isLightProduct
              ? tabs
              : [
                  {
                    name: 'Abgeschlossene Vorgänge',
                    status: [
                      'contracts_with_vehicle_returned_yearly_count',
                      'contracts_with_vehicle_returned_monthly_count',
                      'contracts_with_vehicle_returned_last_six_month_splitted_count',
                    ],
                    id: 0,
                    hasCounter: false,
                  },
                  ...tabs,
                ]
          }
          onSelect={handleTabSelect}
          current={current ? current.id : null}
          counter={0}
        />
        {isHeadQuarter && (
          <div className="flex flex-col mt-5 xl:mt-8 justify-between">
            <SearchSortHeader
              divider={false}
              // title={getResultsBadedOnOpenedTab()}
              searchPLaceholder="Suche"
              selectMenuItem={memberPageSortSelectObject}
              onSelect={setSelected}
              selected={selected}
              handleSearch={setSearchQuery}
            />
          </div>
        )}
        <div className="h-full pt-3">
          <FetchHandlerWrapper
            emptyDataMessage={{
              title: 'Alles erledigt für heute!',
              subtitle: 'Genießen Sie Ihren Tag.',
            }}
            isArray={false}
            isError={isError}
            isLoading={isLoading}
            data={statistics ? statistics.stats : undefined}>
            {(stats: Statsresponse) => {
              return (
                //@ts-ignore
                <Table
                  hasPagination={false}
                  setPage={() => null}
                  currentpage={0}>
                  <TableHead>
                    {columnsUserDetailsPage.map(
                      (item: string, index: number) => {
                        if (index === 3) {
                          return (
                            <TableCell key={item}>
                              <div className="shadow-sm border border-gray-300 bg-white  md:w-48 w-full">
                                <SelectList
                                  items={getLast6Month()}
                                  defaultValue={'AKTUELLER MONAT'}
                                  selected={selectedMonth}
                                  onSelect={setSelectedMonth}
                                />
                              </div>
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={item}>{item}</TableCell>;
                        }
                      },
                    )}
                  </TableHead>
                  <TableBody>
                    {/* 
                    
                    this is the first line of table which shows the data for either a branch or headquarter
                    */}
                    {currentOfficeData && (
                      <TableRow action={() => null}>
                        <TableData className={`text-gray-500 font-sm`}>
                          {currentOfficeData ? currentOfficeData.name : '-'}
                        </TableData>
                        <TableData className={`text-gray-500`}>
                          <div className="ml-0">
                            <div className="text-sm text-gray-500">
                              {currentOfficeData && currentOfficeData.address
                                ? currentOfficeData.address.city
                                : '-'}
                            </div>
                          </div>
                        </TableData>
                        <TableData className={`text-gray-500`}>
                          {currentOfficeData && currentOfficeData.address
                            ? currentOfficeData.address.street
                            : '-'}
                        </TableData>
                        <TableData className={`text-gray-500`}>
                          <div className="text-sm font-bold text-gray-900">
                            {/* @ts-ignore */}
                            {/* {stats[current.status[1]]} */}
                            {selectedMonth && selectedMonth.id
                              ? // @ts-ignore
                                stats[current.status[2]][
                                  // @ts-ignore
                                  selectedMonth.id
                                ]
                              : // @ts-ignore
                                stats[current.status[1]]}
                          </div>
                        </TableData>
                        <TableData className={`text-gray-500`}>
                          <div className="text-sm font-bold text-gray-900">
                            {/* @ts-ignore */}
                            {stats[current.status[0]]}
                          </div>
                        </TableData>
                      </TableRow>
                    )}
                    {/* 
                    
                   incase of a headquarter teh next line will show all the branch offices belongs to this headquarter.
                    */}
                    {isHeadQuarter &&
                    stats.branch_offices_stats &&
                    stats.branch_offices_stats.length
                      ? stats.branch_offices_stats
                          .filter((data: Stats) =>
                            data.branch_office.name
                              .toLowerCase()
                              .includes(query.toLowerCase()),
                          )
                          //@ts-ignore
                          .sort(sortTypes[selected?.status])
                          .map((data: Stats) => {
                            //@ts-ignore

                            const { branch_office } = data;
                            //@ts-ignore
                            const { id, name, address } = branch_office;

                            return (
                              <TableRow key={id} action={() => null}>
                                <TableData className={`text-gray-500 font-sm`}>
                                  {name}
                                </TableData>
                                <TableData className={`text-gray-500`}>
                                  <div className="ml-0">
                                    <div className="text-sm text-gray-500">
                                      {address ? address.city : '-'}
                                    </div>
                                  </div>
                                </TableData>
                                <TableData className={`text-gray-500`}>
                                  {address ? address.street : '-'}
                                </TableData>
                                <TableData className={`text-gray-500`}>
                                  <div className="text-sm font-bold text-gray-900">
                                    {selectedMonth && selectedMonth.id
                                      ? // @ts-ignore
                                        data[current.status[2]][
                                          // @ts-ignore
                                          selectedMonth.id
                                        ]
                                      : // @ts-ignore
                                        data[current.status[1]]}
                                  </div>
                                </TableData>
                                <TableData className={`text-gray-500`}>
                                  <div className="text-sm font-bold text-gray-900">
                                    {/* @ts-ignore */}
                                    {data[current.status[0]]}
                                  </div>
                                </TableData>
                              </TableRow>
                            );
                          })
                      : null}
                  </TableBody>
                </Table>
              );
            }}
          </FetchHandlerWrapper>
        </div>
      </div>
      <Modal
        setOpen={setTokenModalOpen}
        open={tokenModal}
        title="Ihre Sicherheit ist uns wichtig!"
        subtile="Ihre Zwei-Faktor-Authentifizierung (2FA) ist abgelaufen oder Ihre Sitzung wurde beendet. Bitte melden Sie sich erneut an, um fortzufahren.">
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              localStorage.removeItem('user');
              localStorage.removeItem('token');
              setUserToStorage(false);
              auth.signout();
              window.location.reload();
              history.push(`${url}/login`);
              console.log('click ok on token modal');
            }}
            label="Jetzt anmelden"
            type="primary"
            className="w-full justify-center "
          />
        </div>
        {/* <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setModalOpen(false);
              setCloseModalWithoutContract(false);
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center "
          />
        </div> */}
      </Modal>
    </div>
  );
};

export default Dashboard;
