import { useState, Fragment } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { Disclosure } from '@headlessui/react';
import LogoMain from 'assets/images/checktastic.jpg';
import Steps from './steps';
import FormAction from './action';
import idCardBack from 'assets/images/id-back.jpg';
import { Query } from 'shared';
import idCardFront from 'assets/images/id-front.jpg';
import successImgUrl from 'assets/images/success.png';

import HandleStepWithCamera from './step_content_with_camer';
import { ErrorPage } from '../../../../shared';
import { Spinner } from '../../../../../styles/icons';

// type ComposedProps = RouteComponentProps<{
//   documentType: 'PASSPORT' | 'DRIVERS_LISCENSE' | 'IDENTITY_CARD';
// }>;

const IDCheckMobile = ({ location }: RouteComponentProps) => {
  const [openTab, setOpenTab] = useState<string | number>(0);
  const [passOrDrivelicenseError, setPassportOrDriveRequestError] =
    useState(false);
  const [idCardError, setIdentityCardRequestError] = useState(false);
  const [isCameraMode, setCameraMode] = useState(false);
  const [src, setSrc] = useState<{
    url: string;
    height: number;
    width: number;
  } | null>(null);
  const [passOrIdSrc, setPassOrIdSrc] = useState(null);
  const { search } = location;
  const { documentType } = queryString.parse(search);
  const isIDCard =
    documentType === 'IDENTITY_CARD' || documentType === 'RESIDENCE_PERMIT';
  //@ts-ignore
  let { token } = useParams();
  // console.log('🚀 ~ file: index.tsx:38 ~ IDCheckMobile ~ token:', token);
  // console.log('image src', src);
  // const frontImageUrl = openTab === 0 && !isIDCard ? passImage : idCardFront;
  const { FetchExternalImageCaptureProcess, SubmitDataToserver } = Query();
  const { data, isLoading, isError } = FetchExternalImageCaptureProcess({
    key: `mobile-data-${token}`,
    enabled: true,
    token: token,
  });

  const { mutateAsync, isLoading: isSendingData } = SubmitDataToserver({
    url: `external_image_capturing_processes/${token}/capture`,
  });

  const handleFrontSideDocumentRequest = async (data: any) => {
    const DataToBeSendToServer = {
      external_image_capturing_process: {
        identity_document_images_attributes: [data],
      },
    };
    // console.log('DataToBeSendToServer', DataToBeSendToServer);
    try {
      await mutateAsync(DataToBeSendToServer);
    } catch (e) {
      setPassportOrDriveRequestError(true);
      // console.log('error sending frontside document', e);
    }
  };
  const stepsInfo = `Schritt ${Number(openTab) + 1} von ${
    isIDCard ? '3' : '2'
  }`;
  const handleSaveImageToWebApp = async () => {
    if (isIDCard || documentType === 'PASSPORT') {
      if (!passOrIdSrc && openTab === 0) {
        let external_image_data = {
          image: src?.url,
          height: src?.height,
          width: src?.width,
          cropped: 0,
          device_type: 5,
          identity_document_side: 'front_side',
        };

        if (documentType === 'PASSPORT') {
          await handleFrontSideDocumentRequest(external_image_data);
        } else {
          //@ts-ignore
          setPassOrIdSrc(external_image_data);
        }
      } else {
        let back_side_data = {
          image: src?.url,
          height: src?.height,
          width: src?.width,
          cropped: 0,
          device_type: 4,
          identity_document_side: 'back_side',
        };
        //send data to store after taking the backside image
        const dataTosend = {
          external_image_capturing_process: {
            identity_document_images_attributes: [passOrIdSrc, back_side_data],
          },
        };
        try {
          await mutateAsync(dataTosend);
        } catch (e) {
          setIdentityCardRequestError(true);
          // console.log('error', e);
        }
      }
    } else {
      // console.log('drive license ');
      let drive_license_image_data = {
        image: src?.url,
        height: src?.height,
        width: src?.width,
        cropped: 0,
        device_type: 5,
        identity_document_side: 'front_side',
      };
      await handleFrontSideDocumentRequest(drive_license_image_data);
    }
  };
  // console.log('passOrIdSrc', passOrIdSrc);
  const handleNextStep = async () => {
    await handleSaveImageToWebApp();
    //@ts-ignore
    setOpenTab((prev: number) => {
      if (isIDCard) {
        return Number(prev) + 1;
      } else if (
        documentType === 'PASSPORT' ||
        documentType === 'DRIVERS_LISCENSE'
      ) {
        return 1;
      } else {
        return 0;
      }
    });

    setCameraMode(false);
    setSrc(null);
  };
  if (isLoading)
    return (
      <div className="h-96 items-center flex justify-center align-center w-full">
        <Spinner className="text-orange-700" />
      </div>
    );
  if (
    isError ||
    !data ||
    (data && data.external_image_capturing_process.token !== token) ||
    (data && data.external_image_capturing_process.status !== 'pending')
  )
    return <ErrorPage />;
  return (
    <div className="h-screen">
      <div className="bg-gray-800">
        <Disclosure as="nav" className="bg-gray-800">
          {() => (
            <>
              <div className="max-w-7xl mx-auto">
                <div className="border-b border-gray-700">
                  <div className="flex items-center justify-between h-14 px-3">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-14 w-20"
                          src={LogoMain}
                          alt="mainLogo"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="block ml-2 text-center  font-bold text-orange-500">
                        Checktastic ID Checks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>

      <main className="w-full">
        <div className="px-3 pt-2 flex flex-col">
          {/* @ts-ignore */}
          <Steps openTab={openTab} documentType={documentType} />
          <>
            {openTab === 0 && (
              <HandleStepWithCamera
                //@ts-ignore
                documentType={documentType}
                stepsInfo={stepsInfo}
                isCameraMode={isCameraMode}
                setCameraMode={setCameraMode}
                src={src}
                setSrc={setSrc}
                url={idCardFront}
                subTitle={
                  'Halten Sie Ihr Telefon oder Tablet hochkant. Bitte achten Sie darauf, dass das Dokument zentriert ist und die Abstände rechts und links etwa gleich sind. Siehe Beispielbild. Wichtig: Stellen Sie sicher, dass alle Details gut lesbar sind.'
                }
                title={
                  isIDCard
                    ? 'Vorderseite Personalausweis'
                    : 'Vorderseite Reisepass oder Führerschein'
                }
              />
            )}

            {openTab === 1 && isIDCard && (
              <HandleStepWithCamera
                documentType={documentType}
                stepsInfo={stepsInfo}
                isCameraMode={isCameraMode}
                setCameraMode={setCameraMode}
                src={src}
                setSrc={setSrc}
                url={idCardBack}
                subTitle={
                  'Halten Sie Ihr Telefon oder Tablet hochkant. Bitte achten Sie darauf, dass das Dokument zentriert ist und die Abstände rechts und links etwa gleich sind. Siehe Beispielbild. Wichtig: Stellen Sie sicher, dass alle Details gut lesbar sind.'
                }
                title={'Rückseite Personalausweis'}
              />
            )}
            {openTab === 1 &&
              (documentType === 'PASSPORT' ||
                documentType === 'DRIVERS_LISCENSE') && (
                <HandleStepWithCamera
                  stepsInfo={stepsInfo}
                  isCameraMode={isCameraMode}
                  setCameraMode={setCameraMode}
                  isError={passOrDrivelicenseError}
                  isLastStep={openTab === 1}
                  url={successImgUrl}
                  subTitle={
                    passOrDrivelicenseError
                      ? 'Es konnten keine Bilder übertragen werden. Bitte schließen Sie dieses Fenster und versuchen Sie es später erneut.'
                      : 'Sie können diese Seite jetzt schließen. Ihre Aufnahmen wurden übertragen.'
                  }
                  title={
                    passOrDrivelicenseError
                      ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
                      : 'Aufnahmeprozess abgeschlossen'
                  }
                />
              )}

            {openTab === 2 && (
              <HandleStepWithCamera
                stepsInfo={stepsInfo}
                isCameraMode={isCameraMode}
                setCameraMode={setCameraMode}
                isError={idCardError}
                isLastStep={openTab === 2}
                url={successImgUrl}
                subTitle={
                  idCardError
                    ? 'Es konnten keine Bilder übertragen werden. Bitte schließen Sie dieses Fenster und versuchen Sie es später erneut.'
                    : 'Sie können diese Seite jetzt schließen. Ihre Aufnahmen wurden übertragen.'
                }
                title={
                  idCardError
                    ? 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
                    : 'Aufnahmeprozess abgeschlossen'
                }
              />
            )}

            {openTab !== 2 && (
              <div className={'py-1'}>
                <FormAction
                  isLoading={isSendingData}
                  hideBtn={!!src}
                  openTab={openTab}
                  handleNextStep={handleNextStep}
                />
              </div>
            )}
          </>
        </div>
      </main>
    </div>
  );
};

export default IDCheckMobile;
