import { Spinner } from '../../../../../styles/icons';

const FormAction = ({
  openTab,
  handleNextStep,
  isLoading,
  hideBtn = false,
}: {
  openTab: number | string;
  handleNextStep: () => void;
  isLoading?: boolean;
  hideBtn: boolean;
}) => {
  const btnClassName =
    'w-full border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600';

  return (
    <div className="py-1 text-right flex justify-between">
      {/* <div className="order-first">
       {openTab !== 0 ? (
          <button
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              console.log('bacl');
            }}
            type="button"
            className=" bg-white border rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100">
            Zurück
          </button>
        ) : null} 
      </div> */}
      {Number(openTab) < 2 && hideBtn && (
        <button
          onClick={() => {
            // we should validate on mount by adding validateonmount prop to form
            handleNextStep();
          }}
          disabled={isLoading}
          type="button"
          className={`${btnClassName} ${
            isLoading ? 'bg-gray-400' : 'bg-orange-500'
          }`}>
          {isLoading ? (
            <div className="flex items-center align-center">
              <span>Loading</span> <Spinner className="h-4 w-4 ml-2" />
            </div>
          ) : (
            'Weiter'
          )}
        </button>
      )}
      {/* {openTab === 2 && (
        <button
          //  disabled={isSubmitting}
          type="submit"
          className={classNames(
            false
              ? 'bg-orange-500 hover:bg-orange-600 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed',
            'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          )}
          // className="bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isLoading ? (
            <div className="flex items-center align-center">
              <span>Vertrag generieren</span>{' '}
              <Spinner className="h-4 w-4 ml-2" />
            </div>
          ) : (
            'Submit'
          )}
        </button>
      )} */}
    </div>
  );
};

export default FormAction;
