//@ts-ignore
// import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
// import Webcam from 'react-webcam';
import { Button } from '../../../../../styles/components';
// import useStore from '../../../../ui-state/ui';
// import CircleButton from './circle_btn';
// import svgIcon from './svg_overlay';
// import imageCompression from 'browser-image-compression';
import { Spinner } from '../../../../../styles/icons';
import ImageCropp from './image-cropp';

const WebcamCapture = ({
  getImageSrc,
  src,
  isCameraMode,
  isLoading,
  setCameraMode,
  mobileImgsrc,
  setImgSrc,
}: // documentType,
{
  isCameraMode?: boolean;
  documentType?: string;
  isLoading: any;
  setCameraMode: any;
  mobileImgsrc: any;
  setImgSrc: any;
  src?: {
    url: string;
    height: number;
    width: number;
  } | null;
  getImageSrc: (
    src: {
      url: string;
      height: number;
      width: number;
    } | null,
  ) => void;
}) => {
  const [canvasData, setCanvasData] = useState(null);

  useEffect(() => {
    if (canvasData) {
      getImageSrc({
        //@ts-ignore
        url: canvasData.url,
        //@ts-ignore
        height: canvasData.height,
        //@ts-ignore
        width: canvasData.width,
      });
    }
  }, [canvasData, getImageSrc]);

  if (isLoading)
    return (
      <div className="h-96 items-center flex justify-center align-center w-full">
        <Spinner className="text-orange-700" />
      </div>
    );
  return (
    <>
      {!mobileImgsrc ? null : (
        <div className="my-2">
          <ImageCropp
            src={mobileImgsrc}
            setCanvasData={setCanvasData}
            // mainSrc={src}
          />
          <div className="mt-4 max-w-full mx-auto">
            <Button
              endIcon={<TrashIcon className="w-6 h-6 text-red-600" />}
              onClick={() => {
                setImgSrc(null);
                getImageSrc(null);
                //tis step might not be neesecey when the api is working
                setCameraMode(false);
                // setIsShowVideo(true);
                // setDevices([]);
                // setDeviceId({});
              }}
              label=""
              type="default"
              className="justify-center w-full hover:bg-white" //w-full
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WebcamCapture;
