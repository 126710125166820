import { useRef, useEffect } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

const ImageCropp = ({
  src,
  setCanvasData,
}: {
  src: any;
  setCanvasData: any;
}) => {
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) {
      const cropper = new Cropper(imgRef.current, {
        aspectRatio: 16 / 9,
        autoCropArea: 1,
        zoomOnWheel: false,
        zoomOnTouch: false,
        zoomable: false,
        autoCrop: true,
        guides: true,
        dragMode: 'none',
        viewMode: 1,
        checkOrientation: true,
        rotatable: true,
        scalable: true,
        // minCropBoxHeight: 300,
        cropBoxResizable: false,
        cropBoxMovable: true,
        // minCropBoxHeight: ,
        crop(event: any) {
          const canvas = cropper.getCroppedCanvas();

          setCanvasData({
            //@ts-ignore
            url: canvas.toDataURL('image/jpeg'),
            height: event.detail.height,
            width: event.detail.width,
          });
          // console.log('imgData', imgData);
        },
      });
    }
  }, [src, setCanvasData]);

  return (
    //max-w-lg mx-auto
    // adding  max-h-96 to the container to make screen smaller so btn will apear on safari browser
    <div className="rounded-lg overflow-hidden block max-h-96">
      <img
        ref={imgRef}
        src={src}
        alt={'captureedimage'}
        className="object-center object-contain h-full w-full max-w-full"
      />
    </div>
  );
};

export default ImageCropp;
