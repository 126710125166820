import Step from './step';
import { classNames } from '../../../../styles/utils';
import type { ICntractConfig } from './types';

const Steps = ({
  openTab,
  currentStepObject,
}: {
  openTab: string | number;
  currentStepObject: Array<ICntractConfig>;
}) => {
  return (
    <div className="lg:border-t lg:border-b lg:border-gray-200">
      <nav className="w-full px-1 sm:px-6 lg:px-5" aria-label="Progress">
        <ol className="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none">
          {/* we use activeOnLightProduct to return certain item if the product is light so it have just sb insurance contract */}
          {currentStepObject.map((step, stepIdx) => (
            <li
              key={step.id}
              className="relative overflow-hidden lg:flex-1"
              //  onClick={(e) => {
              //    e.preventDefault();
              //    setOpenTab(step.id);
              //  }}
            >
              <div
                className={classNames(
                  openTab === 0 ? 'border-b-0 rounded-t-md' : '',
                  openTab === currentStepObject.length - 1
                    ? 'border-t-0 rounded-b-md'
                    : '',
                  'border border-gray-200 overflow-hidden lg:border-0',
                )}>
                <Step openTab={openTab} {...step} />
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Steps;
