import { Fragment, useState } from 'react';
import WebcamCapture from '../camera/react_camera';
import { ExclamationIcon } from '@heroicons/react/solid';
import { classNames } from '../../../../../styles/utils';
import Resizer from 'react-image-file-resizer';

const HandleStepWithCamera = ({
  url,
  title,
  subTitle,
  isCameraMode,
  src,
  setSrc,
  setCameraMode,
  isLastStep = false,
  stepsInfo = '',
  documentType = '',
  isError = false,
}: {
  stepsInfo?: string;
  url: string;
  title: string;
  subTitle: string;
  isCameraMode: boolean;
  src?: {
    url: string;
    height: number;
    width: number;
  } | null;
  setSrc?: (
    src: {
      url: string;
      height: number;
      width: number;
    } | null,
  ) => void;
  setCameraMode: (mode: boolean) => void;
  isLastStep?: boolean;
  documentType?: string;
  isError?: boolean;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [mobileImgsrc, setImgSrc] = useState(null);

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        3500,
        3000,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
        1999,
        1999,
      );
    });

  const handleCapture = async (target: any) => {
    setLoading(true);
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        // console.log('filee==,', file);
        // const options = {
        //   maxSizeMB: 2,
        //   maxWidthOrHeight: 1024,
        //   useWebWorker: true,
        // };
        try {
          const base64 = await resizeFile(file);
          // const compressedFile = await imageCompression(file, options);
          // console.log('base64,', base64);
          // const base64 = await imageCompression.getDataUrlFromFile(
          //   compressedFile,
          // );

          // let img = document.createElement('img');
          // //@ts-ignore
          // img.setAttribute('src', base64);

          // setTimeout(function () {
          //   getImageSrc({
          //     //@ts-ignore
          //     url: base64,
          //     height: img.height,
          //     width: img.width,
          //   });

          //   setLoading(false);
          // }, 10);
          //@ts-ignore
          setImgSrc(base64);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          throw error;
        }
      }
    }
  };
  const btnClassName =
    'w-full border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600';
  return (
    <div className="flex flex-col">
      {!isCameraMode && (
        <div className="my-0">
          {!isError ? (
            <div
              className={classNames(
                isLastStep ? 'max-w-xs p-14' : 'max-w-lg p-0',
                'overflow-hidden  mx-auto',
              )}>
              <img
                src={url}
                alt={'idcard'}
                className={classNames(
                  isLastStep ? 'h-32' : 'h-52 w-34',
                  'object-center object-cover mx-auto',
                )}
              />
            </div>
          ) : (
            <div className="flex justify-center p-14">
              <ExclamationIcon className="h-32 w-32 text-red-600" />
            </div>
          )}
        </div>
      )}
      <div className="px-4 text-center flex flex-col">
        {!isCameraMode ? (
          <Fragment>
            <p className="font-bold">{title}</p>
            {stepsInfo && (
              <p className="text-gray-500 p-0 text-sm">{stepsInfo}</p>
            )}
            <p className="text-xs text-gray-600 mb-1 mt-1">{subTitle}</p>
            {!isLastStep && (
              <button>
                <label
                  htmlFor="file-upload"
                  onChange={(e) => {
                    setCameraMode(true);
                    handleCapture(e.target);
                  }}
                  className={`${btnClassName} bg-orange-500 hover:bg-orange-600 my-4`}>
                  <span>Start</span>

                  <input
                    className="sr-only"
                    accept="image/*"
                    id="file-upload"
                    type="file"
                    capture="environment"
                  />
                </label>
              </button>
            )}
          </Fragment>
        ) : null}
        {isLastStep && (
          <button
            type="button"
            className={` px-4 py-2 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-green-200 hover:bg-gray-50 bg-white my-4  border`}
            onClick={() => {
              window.close();
            }}>
            <span className="text-green-500 font-bold text-base">
              Fenster schließen
            </span>
          </button>
        )}
      </div>

      {!isLastStep && (
        <WebcamCapture
          isLoading={isLoading}
          setLoading={setLoading}
          setImgSrc={setImgSrc}
          setCameraMode={setCameraMode}
          mobileImgsrc={mobileImgsrc}
          documentType={documentType}
          isCameraMode={isCameraMode}
          src={src}
          //@ts-ignore
          getImageSrc={setSrc}
          // setCameraLoaded={setCameraLoaded}
        />
      )}
    </div>
  );
};

export default HandleStepWithCamera;
