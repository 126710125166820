/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Field, FormikProps } from 'formik';
import { Query, Emails } from 'shared';
import { useHistory } from 'react-router-dom';

import { ExclamationIcon } from '@heroicons/react/outline';
import { Form, Modal, NotificationMessage } from '../../../shared';
import { Spinner } from '../../../../styles/icons';
import { classNames } from '../../../../styles/utils';
import { Button } from '../../../../styles/components';
import { creatLegitimationFormSchema } from '../utils';
import QRCodePopup from './qr_code_popup';
import SelectedScanPopover from './scan_select_popover';
import CaptureDocumentButton from './capture_by_camera_select';
import SelectDocumentFieldWrapper from './select_document_field_wrapper';
// import useStore from '../../../../ui-state/ui';

import './index.scss';
import config from 'shared/config';

type FieldProps = {
  field: { name: string; value: string };
  form: any;
  meta: {
    touched: boolean;
    error: any;
  };
};
export type ScandeDocProp = {
  id: number;
  subject: string;
  pdf_attachment_file?: {
    url: string;
  };
  recieved_at?: string;
};
const NewLegitimation = ({
  userOwnerType,
  userOwnerId,
  isJustIdCheck,
}: {
  userOwnerId: number;
  userOwnerType: string;
  isJustIdCheck?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [notification, setOpenNotification] = useState<{
    isOpen: boolean;
    text: string;
  }>({ isOpen: false, text: '' });
  const [showScanMenu, setScanMenu] = useState(false);
  const [selectedScan, setSelectedScanId] = useState<
    ScandeDocProp[] | undefined
  >([]);
  const [showScanDrivelicensMenu, setDrivelicensMenu] = useState(false);
  const [selectedDriveLice, setSDriveLiceId] = useState<ScandeDocProp[]>([]);

  // nable the next 9 lines for camera use;

  const [openModal, setOpenModal] = useState(false);
  const [openLicenseModal, setLicenseOpenModal] = useState(false);
  const [imageScanModalOpen, setImageScanModalOpen] = useState(false);
  const [scanImageSrcPassOrID, setScanedImagesSrcPassOrID] = useState([]);
  //@ts-ignore
  const [scanImageSrDrive, setScanedImagesSrc] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [passOrIdSrc, setPassOrIdSrc] = useState(null);
  const [driveLicenseSrc, setDriveLicenseSrc] = useState(null);
  // const [didUserAcceptImageQuality, setUserImageQuality] = useState(false);

  // const setPassOrIdSrcStore = useStore((state) => state.setPassOrIdSrc);
  // const passOrIdSrcStore = useStore((state) => state.passOrIdSrc);

  // console.log('passOrIdSrc', passOrIdSrc);
  // console.log('driveLicenseSrc', driveLicenseSrc);
  // console.log('selectedDocumentType', selectedDocumentType);
  // console.log('selectedDriveLice', selectedDriveLice);

  // isSelectedScan.length > 1 because the user need to add 2 side of the passport or id card
  const isSelectedScan = selectedScan && selectedScan.length > 1 ? true : false;
  const isSelectedScanPassport = selectedScan && selectedScan.length > 0;
  // console.log('selectedScan', selectedScan);
  // console.log('selectedDriveLice', selectedDriveLice.length);

  const history = useHistory();
  // const { url } = useRouteMatch();
  const isHeadQuarter = userOwnerType === 'Dealer::Headquarter';

  const {
    SubmitDataToserver,
    FetchUScanToMainData,
    FetchDictionarysData,
    creatLegitimation,
    FetchHeadquarterDataById,
    FetchBranchOfficeDataById,
  } = Query();

  const {
    data: headQuarterData,
    error: headQuarterError,
    isError: isHeadQuarterError,
  } = FetchHeadquarterDataById({
    key: 'head_quarter',
    id: userOwnerId,
    enabled: isHeadQuarter,
  });

  const {
    data: branchOfficeData,
    error: isBranchOfficeError,
    isError: isBranchError,
  } = FetchBranchOfficeDataById({
    key: 'branch_office',
    id: userOwnerId,
    enabled: !isHeadQuarter,
  });
  const currentOfficeData = isHeadQuarter ? headQuarterData : branchOfficeData;
  const donwnLoadUrl =
    currentOfficeData &&
    currentOfficeData.instructions_file &&
    currentOfficeData.instructions_file.orignal
      ? config.baseURL + currentOfficeData.instructions_file.orignal
      : undefined;
  // console.log('currentOfficeData', currentOfficeData);
  const { mutateAsync, isLoading, reset } = SubmitDataToserver({
    url: '/id_checks',
  });

  const { data, isSuccess } = FetchUScanToMainData({
    key: 'scan-to-mail',
    per_page: 150,
    context: 'unassigned',
  });
  const { data: dictionaryData } = FetchDictionarysData({
    key: 'dictionary-data',
  });
  const handleCreateLegitimation = async (values: any) => {
    let identity_document_images =
      passOrIdSrc && passOrIdSrc['visible1-base64']
        ? [
            {
              image: passOrIdSrc['visible1-base64'],
              height: passOrIdSrc['visible1-height'],
              width: passOrIdSrc['visible1-width'],
              cropped: 0,
              device_type: 5,
              identity_document_side: 'front_side',
            },
          ]
        : null;
    if (passOrIdSrc && passOrIdSrc['visible2-base64']) {
      identity_document_images &&
        identity_document_images.push({
          image: passOrIdSrc['visible2-base64'],
          height: passOrIdSrc['visible2-height'],
          width: passOrIdSrc['visible2-width'],
          cropped: 0,
          device_type: 5,
          identity_document_side: 'back_side',
        });
    }
    const formData = {
      id_check: {
        identity_document_type: values.decumenttype,
        scan_to_mail_email1_id:
          selectedScan && selectedScan.length ? selectedScan[0].id : null,
        //@ts-ignore
        scan_to_mail_email2_id: isSelectedScan ? selectedScan[1].id : null,
        drivers_liscense_scan_to_mail_email1_id:
          selectedDriveLice && selectedDriveLice.length
            ? selectedDriveLice[0].id
            : null,
        drivers_liscense_scan_to_mail_email2_id:
          selectedDriveLice && selectedDriveLice.length > 1
            ? selectedDriveLice[1].id
            : null,
        // we send empty value for first and last name because the cutomer decided to remove the field from id check.
        prename: '',
        surname: '',
        identity_document_images_attributes: identity_document_images,
        drivers_liscense_id_check_attributes: driveLicenseSrc
          ? {
              identity_document_images_attributes: [
                {
                  image: driveLicenseSrc!['visible1-base64'],
                  height: driveLicenseSrc!['visible1-height'],
                  width: driveLicenseSrc!['visible1-width'],
                  cropped: 0,
                  device_type: 5,
                  identity_document_side: 'front_side',
                },
              ],
            }
          : null,
      },
    };
    try {
      const responseData = await mutateAsync(formData);
      if (responseData) {
        //@ts-ignore
        creatLegitimation({ id: responseData.data.id });
        setOpen(true);
        reset();
      }
    } catch (e) {
      //@ts-ignore
      // console.log('eee', e.response);
      setOpen(false);
      setOpenNotification((odl) => ({
        isOpen: true,
        //@ts-ignore
        text: e.response.data.errors,
      }));
      reset();
    }
  };
  const filterDriveLiceseScanBasedOnIdScan = (data: any) => {
    let itemToBeRemoved = isSelectedScan
      ? //@ts-ignore
        selectedScan.map((scan) => scan.id)
      : [];

    return (
      data &&
      data.emails.filter((email: Emails) => !itemToBeRemoved.includes(email.id))
    );
  };
  // added next line becasue of the last feuture about hiding drive license when product is Basic/light
  // we'r excluding the driveLicense related values when we isJustIdCheck is true
  const isBtnDisabled = isJustIdCheck
    ? isSelectedScan === false &&
      !passOrIdSrc &&
      isSelectedScanPassport === false
    : (isSelectedScan === false &&
        !passOrIdSrc &&
        isSelectedScanPassport === false) ||
      (selectedDriveLice.length < 1 && !driveLicenseSrc);

  // console.log('selectedDriveLice', selectedDriveLice.length > 0);
  // console.log('isSelectedScan', isBtnDisabled);
  const isDisabledClass = isJustIdCheck
    ? isSelectedScan === false &&
      !passOrIdSrc &&
      isSelectedScanPassport === false
    : (isSelectedScan === false &&
        !passOrIdSrc &&
        isSelectedScanPassport === false) ||
      (selectedDriveLice.length < 1 && !driveLicenseSrc);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mt-5">
      <Form
        validateOnBlur={false}
        submitForm={handleCreateLegitimation}
        validationSchema={creatLegitimationFormSchema}
        initialValues={{
          // prename: '',
          // surname: '',
          decumenttype: '',
          drive_liscense: 'DRIVERS_LISCENSE',
        }}>
        {({
          errors,
          isSubmitting,
          dirty,
          isValid,
          values,
        }: FormikProps<any>) => {
          // console.log('errors', errors);
          // console.log('values', values);
          return (
            <>
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                  <div>
                    <h3 className="text-lg leading-6 font-bold text-gray-900">
                      Neuer Kunde
                    </h3>
                  </div>

                  {/* <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="prename"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Vorname <span className="text-red-600">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                          <Field name="prename">
                            {({
                              field,
                              // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }: FieldProps) => {
                              return (
                                <Form.CustomInputItem
                                  field={field}
                                  meta={meta}
                                  placeholder="Vorname"
                                  className="w-full border shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500  sm:text-sm border-gray-400 rounded-md p-2"
                                  type="text"
                                />
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="surname"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Nachname <span className="text-red-600">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                          <Field name="surname">
                            {({
                              field,
                              // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }: FieldProps) => {
                              return (
                                <Form.CustomInputItem
                                  field={field}
                                  meta={meta}
                                  placeholder="Nachname"
                                  className="w-full border shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500  sm:text-sm border-gray-400 rounded-md p-2"
                                  type="text"
                                />
                              );
                            }}
                          </Field>
                        </div>

                        <p className="text-xs text-gray-700 pt-3 ">
                          Die Kunden-ID wird automatisch generiert und
                          zugeordnet.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-xl leading-6 font-bold text-gray-900">
                      Dokumente bereitstellen
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Bitte wählen Sie hier den Ausweistyp und die Scans, die
                      Sie zuweisen und prüfen möchten. Bitte wählen Sie alle
                      notwendigen Scans aus.
                    </p>
                  </div>
                  {/* sssss */}
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Ausweistyp <span className="text-red-600">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-lg">
                        <Field
                          name="decumenttype"
                          component={Form.SelectField}
                          placeholder="decumenttype"
                          options={
                            dictionaryData
                              ? Object.values(
                                  //@ts-ignore
                                  dictionaryData.identity_document_types,
                                )
                                  .map((item: any) => ({
                                    value: item.key,
                                    label: `${item.i18n}`,
                                  }))
                                  .filter(
                                    (item: any) =>
                                      item.value !== 'DRIVERS_LISCENSE',
                                  )
                              : { value: '', label: '' }
                          }
                        />
                      </div>
                    </div>

                    <div className="sm:space-y-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-b sm:border-gray-200 sm:pt-5 sm:pb-10">
                      <div>
                        <label
                          htmlFor="cover_photo"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:py-2">
                          Dokument scannen{' '}
                          <span className="text-red-600 mr-2">*</span>
                          <a
                            target="_blank"
                            href={donwnLoadUrl ? donwnLoadUrl : undefined}
                            // type="button"
                            // className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                            rel="noreferrer">
                            <span
                              className={
                                donwnLoadUrl
                                  ? 'text-orange-500 cursor-pointer'
                                  : 'text-gray-400'
                              }>
                              Anleitung
                            </span>
                          </a>
                        </label>
                        <p className="text-xs text-gray-700 break-words lg:w-6/12 py-3">
                          Bitte wählen Sie hier Ausweistyp und die Scans die Sie
                          zuweisen und prüfen möchten. Bitte wählen Sie alle
                          notwendigen Scans aus (Hinweis: Denken Sie an Vorder-
                          und Rückseite) .
                        </p>
                      </div>
                      {/* dekoument scan */}
                      {/* add this class later for camera use: flex items-center */}
                      <div className="mt-1 sm:mt-0 sm:col-span-2 pb-8 flex flex-col lg:flex-row items-center justify-center lg:justify-start">
                        <SelectDocumentFieldWrapper>
                          <SelectedScanPopover
                            //@ts-ignore
                            // shouldCloseSelectMenu={
                            //   values['decumenttype'] === 'PASSPORT'
                            //     ? isSelectedScanPassport
                            //     : selectedScan && selectedScan.length
                            // }
                            type={values['decumenttype']}
                            data={data && data.emails}
                            isSuccess={isSuccess}
                            setPopoverOpen={setScanMenu}
                            selectedScan={selectedScan}
                            setSelectedScanId={setSelectedScanId}
                            showPopover={showScanMenu}
                            isSelectScanPossible={
                              //add:!passOrIdSrc for camera use
                              data && data.emails.length
                            }
                            shouldOpenPopover={
                              // add:!passOrIdSrc  for camera use
                              values['decumenttype'] === 'PASSPORT'
                                ? data &&
                                  data.emails.length &&
                                  selectedScan &&
                                  selectedScan.length === 0
                                : data && data.emails.length && !isSelectedScan
                            }
                          />
                        </SelectDocumentFieldWrapper>

                        <div className="mx-2 lg:mx-6 text-gray-500 py-2 lg:py-0 font-semibold">
                          Oder
                        </div>
                        <SelectDocumentFieldWrapper isCameraScan>
                          {/* <QRcode /> */}
                          <CaptureDocumentButton
                            //@ts-ignore
                            isSelectedScan={isSelectedScan}
                            //@ts-ignore
                            selectedScan={passOrIdSrc}
                            setSelectedScanId={setPassOrIdSrc}
                            onImageshowClick={() => {
                              setSelectedDocumentType('pass');
                              setScanedImagesSrcPassOrID([
                                //@ts-ignore
                                passOrIdSrc['visible1-base64'],
                                //@ts-ignore
                                passOrIdSrc['visible2-base64'],
                              ]);
                              setImageScanModalOpen(true);
                            }}
                            setSelectedDocumentType={setSelectedDocumentType}
                            setScanedImagesSrcPassOrID={
                              setScanedImagesSrcPassOrID
                            }
                            setImageScanModalOpen={setImageScanModalOpen}
                            setOpenModal={setOpenModal}
                          />
                        </SelectDocumentFieldWrapper>
                      </div>
                    </div>
                  </div>
                  {/* start second decument selected */}
                  {isJustIdCheck ? null : (
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Führerschein <span className="text-red-600">*</span>
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-lg">
                          <Field
                            name="drive_liscense"
                            component={Form.SelectField}
                            placeholder="drive_liscense"
                            isDisabled
                            options={[
                              {
                                value: 'DRIVERS_LISCENSE',
                                label: 'Führerschein',
                              },
                            ]}
                          />
                        </div>
                      </div>

                      <div className="sm:space-y-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-b sm:border-gray-200 sm:pt-5 sm:pb-10">
                        <div>
                          <label
                            htmlFor="cover_photo"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Dokument scannen{' '}
                            <span className="text-red-600 mr-2">*</span>
                            <a
                              target="_blank"
                              href={donwnLoadUrl ? donwnLoadUrl : undefined}
                              // type="button"
                              // className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                              rel="noreferrer">
                              <span
                                className={
                                  donwnLoadUrl
                                    ? 'text-orange-500 cursor-pointer'
                                    : 'text-gray-400'
                                }>
                                Anleitung
                              </span>
                            </a>
                          </label>
                          <p className="text-xs text-gray-700 break-words lg:w-6/12  py-3 ">
                            Bitte wählen Sie hier Führerschein und die Scans die
                            Sie zuweisen und prüfen möchten.
                          </p>
                        </div>
                        {/* dekoument scan */}
                        {/* add this class later for camera use: flex items-center */}
                        <div className="mt-1 sm:mt-0 sm:col-span-2 pb-8 flex flex-col lg:flex-row items-center justify-center lg:justify-start">
                          <SelectDocumentFieldWrapper>
                            <SelectedScanPopover
                              data={filterDriveLiceseScanBasedOnIdScan(data)}
                              isSuccess={isSuccess}
                              type="drive_liscense"
                              setPopoverOpen={setDrivelicensMenu}
                              //@ts-ignore
                              selectedScan={selectedDriveLice}
                              // shouldCloseSelectMenu={
                              //   selectedDriveLice.length > 0
                              // }
                              //@ts-ignore
                              isSelectScanPossible={
                                // replace with (isSelectedScan || passOrIdSrc) &&
                                // selectedDriveLice.length === 0 &&
                                // !driveLicenseSrc &&
                                // data &&
                                // data.emails.length,
                                Boolean(
                                  (isSelectedScan ||
                                    isSelectedScanPassport ||
                                    passOrIdSrc) &&
                                    selectedDriveLice.length === 0 &&
                                    !driveLicenseSrc &&
                                    data &&
                                    data.emails.length,
                                )
                              }
                              setSelectedScanId={setSDriveLiceId}
                              showPopover={showScanDrivelicensMenu}
                              //@ts-ignore
                              shouldOpenPopover={
                                //replace with: (isSelectedScan || passOrIdSrc) &&
                                // selectedDriveLice.length === 0 &&
                                // !driveLicenseSrc &&
                                Boolean(
                                  (isSelectedScan ||
                                    isSelectedScanPassport ||
                                    passOrIdSrc) &&
                                    selectedDriveLice.length === 0 &&
                                    !driveLicenseSrc &&
                                    data &&
                                    data.emails.length,
                                )
                              }
                            />
                          </SelectDocumentFieldWrapper>

                          {/* second modal */}
                          <div className="mx-2 lg:mx-6 text-gray-500 py-2 lg:py-0 font-semibold">
                            Oder
                          </div>
                          <SelectDocumentFieldWrapper isCameraScan>
                            <CaptureDocumentButton
                              isSelectedScan={Boolean(
                                (!isSelectedScan && !passOrIdSrc) ||
                                  selectedDriveLice.length,
                              )}
                              //@ts-ignore
                              selectedScan={driveLicenseSrc}
                              setSelectedScanId={setDriveLicenseSrc}
                              onImageshowClick={() => {
                                setSelectedDocumentType('drive_liscense');
                                setScanedImagesSrc([
                                  //@ts-ignore
                                  driveLicenseSrc?.['visible1-base64'],
                                  // //@ts-ignore
                                  // driveLicenseSrc?.['visible2-base64'],
                                ]);
                                setImageScanModalOpen(true);
                              }}
                              setSelectedDocumentType={setSelectedDocumentType}
                              onScanCli
                              setScanedImagesSrcPassOrID={
                                setScanedImagesSrcPassOrID
                              }
                              setImageScanModalOpen={setImageScanModalOpen}
                              setOpenModal={setLicenseOpenModal}
                            />
                          </SelectDocumentFieldWrapper>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="pt-1">
                <div className="flex justify-between">
                  <button
                    onClick={() => history.push(`/id_check`)}
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Abbrechen
                  </button>
                  {/* @ts-ignore */}
                  {/* {error && error.response && (
                    //@ts-ignore
                    <div>{error.response.data.errors}</div>
                  )} */}
                  <button
                    disabled={
                      //replace later with
                      // !dirty ||
                      // !!Object.keys(errors).length ||
                      // isSubmitting ||
                      // (isSelectedScan === undefined && !passOrIdSrc) ||
                      // (selectedDriveLice === undefined && !driveLicenseSrc)
                      !dirty ||
                      !!Object.keys(errors).length ||
                      isSubmitting ||
                      isBtnDisabled
                    }
                    type="submit"
                    className={classNames(
                      // replace with: !dirty ||
                      // !!Object.keys(errors).length ||
                      // isSubmitting ||
                      // (isSelectedScan === undefined && !passOrIdSrc) ||
                      // (selectedDriveLice === undefined && !driveLicenseSrc)

                      !dirty ||
                        !!Object.keys(errors).length ||
                        isSubmitting ||
                        isDisabledClass
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer',
                      'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700',
                    )}>
                    {isSubmitting || isLoading ? (
                      <div className="flex items-center align-center">
                        <span> Legitimation prüfen</span>{' '}
                        <Spinner className="h-4 w-4 ml-1" />
                      </div>
                    ) : (
                      'Legitimation prüfen'
                    )}
                  </button>
                </div>
              </div>
              <Modal setOpen={setOpenModal} open={openModal} planDialgo>
                <div>
                  <QRCodePopup
                    setModalOpen={setOpenModal}
                    // setDriveLicenseSrc={() => null}
                    setPassOrIdSrc={setPassOrIdSrc}
                    // type="PASS_OR_ID"
                    // didUserAcceptImageQuality={didUserAcceptImageQuality}
                    // setUserImageQuality={setUserImageQuality}
                  />
                </div>

                <div className="sm:mt-6 max-w-full md:mx-auto md:max-w-sm mx-2">
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                    }}
                    label="Abbrechen"
                    type="default"
                    className="w-full justify-center hover:bg-gray-50 hover:text-black"
                  />
                </div>
              </Modal>
              <Modal
                setOpen={setLicenseOpenModal}
                open={openLicenseModal}
                planDialgo>
                <div>
                  <QRCodePopup
                    setModalOpen={setLicenseOpenModal}
                    // setPassOrIdSrc={() => null}
                    setDriveLicenseSrc={setDriveLicenseSrc}
                    type="DRIVERS_LISCENSE"
                    // didUserAcceptImageQuality={didUserAcceptImageQuality}
                    // setUserImageQuality={setUserImageQuality}
                  />
                </div>
                <div className="sm:mt-6 max-w-full md:mx-auto md:max-w-sm mx-2">
                  <Button
                    onClick={() => {
                      setLicenseOpenModal(false);
                    }}
                    label="Abbrechen"
                    type="default"
                    className="w-full justify-center hover:bg-gray-50 hover:text-black"
                  />
                </div>
              </Modal>
            </>
          );
        }}
      </Form>
      <NotificationMessage
        isError
        title={notification.text}
        // subTitle={
        //   isReturnVehicles
        //     ? 'Sie finden den Vertrag nun unter abgeschlossene Vorgänge'
        //     : ''
        // }
        notify={notification.isOpen}
        setShow={(open: boolean) =>
          setOpenNotification((oldS) => ({ ...oldS, isOpen: open }))
        }
      />
      <Modal
        hasIcon
        setOpen={setOpen}
        open={open}
        title="Legitimation erfolgreich versendet"
        // subtile="lorem ipsum brety breaken testing subtitle to go for anothre place to go"
      >
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpen(false);
              history.push('/id_check');
            }}
            label="okay"
            type="primary"
            className="w-full justify-center "
          />
        </div>
      </Modal>

      <Modal
        // hasIcon
        planDialgo
        setOpen={setImageScanModalOpen}
        open={imageScanModalOpen}
        // title="Legitimation erfolgreich versendet"
        // subtile="lorem ipsum brety breaken testing subtitle to go for anothre place to go"
      >
        <div>
          <div className="rounded-md bg-red-50 p-4 my-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Achtung!</h3>
                <div className="mt-2 text-md text-red-700">
                  <p>
                    Bitte wiederholen Sie den Aufnahmeprozess, falls die Daten
                    auf Ihrer Aufnahme nicht deutlich lesbar sind. Es
                    k&ouml;nnte sonst zu einer Ablehnung des Dokumentes kommen.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {selectedDocumentType === 'drive_liscense'
            ? scanImageSrDrive.map((src: string) => {
                if (src) {
                  return (
                    <img
                      key={src}
                      src={src}
                      alt="sacned images"
                      className="object-cover h-full w-full py-1 rounded-lg"
                    />
                  );
                }
                return null;
              })
            : scanImageSrcPassOrID.map((src: string) => {
                if (src) {
                  return (
                    <img
                      key={src}
                      src={src}
                      alt="sacned  ID or Passport"
                      className="object-cover h-full w-full py-1 rounded-lg"
                    />
                  );
                }
                return null;
              })}
        </div>
        <div className="sm:mt-6 max-w-full mx-auto md:max-w-sm">
          <Button
            onClick={() => {
              setImageScanModalOpen(false);
            }}
            label="schließen"
            type="default"
            className="w-full justify-center hover:bg-gray-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          />
        </div>
      </Modal>
    </div>
  );
};

export default NewLegitimation;
