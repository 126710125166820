import { useContext, useEffect, useState } from 'react';

import { authContext } from '../context';
import { Query, useStores } from 'shared';
import { IAuthObject } from '../context/types';
import useStore from '../ui-state/ui';

export default function useProvideAuth(): IAuthObject {
  const { ui } = useStores();
  const [user2Fac, setUser] = useState(null);
  const { Loggin, Logout, submit2FacCode } = Query();
  const saveUserToStorage = useStore((state) => state.saveUserToStorage);
  const { mutateAsync, data, isError, isLoading, error, reset } = Loggin({
    type: 'LOGIN',
    // is2FacAuth:false,
  });
  // console.log('data useProvideAuth', data);
  // if (error) {
  //   console.log(
  //     'error.response?.headers?.authorization',
  //     //@ts-ignore
  //     error?.response?.headers?.authorization,
  //   );
  // }
  // `/users/two_factor_auth_challenge?code=${code}`
  const { mutate } = Logout({ type: 'LOGOUT' });
  const signin = async (email?: string, password?: string, code?: number) => {
    if (!email && !password && code) {
      const user = await submit2FacCode({ code });
      // console.log('🚀 ~ file: use_auth.ts:23 ~ signin ~ user:', user);
      if (user?.data) {
        // console.log('🚀 ~ file: use_auth.ts:23 ~ signin ~ user:', user);
        setUser(user.data);
        localStorage.setItem(`user`, window.JSON.stringify(user.data));
      }
    } else {
      await mutateAsync({
        api_user: {
          email,
          password,
        },
      });
    }
  };

  const signout = () => {
    mutate({
      user: {
        id: data?.data.id,
      },
    });
    if (user2Fac) {
      setUser(null);
    }
    reset();
  };

  useEffect(() => {
    ui.setToken(data ? data.headers.authorization : null);

    if (data && saveUserToStorage) {
      localStorage.setItem(`user`, window.JSON.stringify(data.data));
      localStorage.setItem(
        `token`,
        window.JSON.stringify(data.headers.authorization),
      );
    }
  }, [data, ui, saveUserToStorage, user2Fac]);

  return {
    user: data ? data.data : user2Fac ? user2Fac : null,

    token: data
      ? data.headers.authorization
      : error
      ? //@ts-ignore
        error.response?.headers?.authorization
      : undefined,

    authorization: data
      ? data.headers.authorization
      : error
      ? //@ts-ignore
        error.response?.headers?.authorization
      : null,
    isError,
    isLoading,
    error,
    signin,
    signout,
  };
}

export function useAuthContext() {
  return useContext(authContext);
}
