import React, { FC, CSSProperties, ChangeEvent } from 'react';
import {
  Form as FormicForm,
  Formik,
  FormikProps,
  useField,
  FieldProps,
} from 'formik';
//@ts-ignore
import Select, { Option, ReactSelectProps } from 'react-select';

// import { useFormik } from 'formik';
export const isFunction = (obj: any): obj is Function =>
  typeof obj === 'function';

type IForm = {
  submitForm: (values: any, action: any) => void;
  children: any;
  validationSchema: any;
  initialValues?: any;
  validateOnMount?: boolean;
  validateOnBlur?: boolean;
};
const Form = ({
  submitForm,
  children,
  validationSchema,
  initialValues,
  validateOnMount = false,
  validateOnBlur,
}: IForm) => {
  return (
    <Formik
      validateOnBlur={validateOnBlur}
      validateOnMount={validateOnMount}
      validationSchema={validationSchema}
      onSubmit={(values, action) => submitForm(values, action)}
      initialValues={initialValues}>
      {(props: FormikProps<any>) => {
        // console.log('props formik', props);

        return (
          <FormicForm className="space-y-6">
            {isFunction(children)
              ? (children as (props: FormikProps<any>) => React.ReactNode)(
                  props as FormikProps<any>,
                )
              : children}
          </FormicForm>
        );
      }}
    </Formik>
  );
};
const FormInputField = ({ type, className, field, meta, placeholder }: any) => {
  return (
    <div className="flex w-full flex-col">
      <input
        type={type}
        className={className}
        {...field}
        placeholder={placeholder}
      />

      {meta && meta.touched && meta && meta.error && (
        <p className="text-red-400 pt-2 text-sm font-semibold">
          {meta && meta.error}
        </p>
      )}
    </div>
  );
};

export interface IFOrmikUiSelectOption {
  label: string;
  value: string | number;
}
export interface IFOrmikUiSelectProps {
  /** Sets the Name of the Select Field */
  name: string;
  /** Adds a custom class to the select element of the Select component */
  className: string;
  /** Adds a custom inline styles to the select element */
  style?: CSSProperties;
  /** Disables the Select Field */
  disabled?: boolean;
  /** Sets an Id for the Select Field, if not passed, the id will be the name */
  id: string;
  /** Sets the main Label for the Select Field */
  label?: string;
  /** Sets a Placeholder as the first option with no value */
  placeholder?: string;
  /** Sets a hint text after/below the Select component */
  hint?: string;
  /** Sets the field as requierd, if label is passed, an * is added to the end of the main label. Validation will only work if you pass the required() method in the yup validation schema */
  required?: boolean;
  /** Allowes multiple selection */
  multiple?: boolean;
}

const Selectf: FC<IFOrmikUiSelectProps> = ({
  disabled,
  id,
  label,
  name,
  placeholder,
  required,
  className,
  style,
  multiple,
  children,
}) => {
  const [{ value, onChange }, , { setValue }] = useField(name);

  const handleMultipleChange = (event: ChangeEvent<HTMLSelectElement>): any =>
    setValue(
      [].slice
        .call(event.target.selectedOptions)
        .map((option: IFOrmikUiSelectOption) => option.value),
    );

  return (
    <select
      onChange={multiple ? handleMultipleChange : onChange}
      id={id || name}
      name={name}
      className={className}
      style={style}
      value={value}
      disabled={disabled}
      multiple={multiple}>
      {placeholder && (
        <option value="">{`${placeholder}${
          !label && required ? ' *' : ''
        }`}</option>
      )}
      {children}
    </select>
  );
};

const colourStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f97316'
        : isFocused
        ? '#f3f4f6'
        : null,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : data.color,
      // cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  control: (styles: any, state: any) => ({
    ...styles,
    boxShadow: 0,
    borderColor: state.isFocused ? 'orange' : 'rgba(209, 213, 219)',
    '&:hover': {
      borderColor: state.isFocused ? 'orange' : '#CED4DA',
    },
  }),
  // input: styles => ({ ...styles, ...dot() }),
  // placeholder: styles => ({ ...styles, ...dot() }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};
const getPlaceHolder = (name: string) => {
  switch (name) {
    case 'decumenttype':
      return 'Ausweistyp...';
    case 'salutation':
      return 'Bitte auswählen...';
    case 'brand':
      return 'Marke & Model...';
    case 'kind':
      return 'Vertragsart...';
    case 'CarKind':
      return 'Fahrzeugkategorie...';
    case 'contract_insurence_id':
      return 'Selbstbeteiligung...';
    case 'user_owner_id':
      return 'Standort...';
    case 'role':
      return 'Rolle...';
    case 'drive_liscense':
      return 'Führerschein';
    default:
      return '';
  }
};
const SelectField: React.FC<ReactSelectProps & FieldProps> = ({
  options,
  field,
  form,
  isDisabled,
}) => {
  const placeHolder = getPlaceHolder(field.name);
  return (
    <Select
      noOptionsMessage={() => 'kein Angabe'}
      isDisabled={isDisabled}
      options={options}
      styles={colourStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      name={field.name}
      placeholder={placeHolder}
      value={
        options && options.length > 0
          ? options.find((option: any) => option.value === field.value)
          : ''
      }
      onChange={(option: Option) =>
        form.setFieldValue(field.name, option.value)
      }
      onBlur={field.onBlur}
    />
  );
};

Form.CustomInputItem = FormInputField;
Form.Select = Selectf;
Form.SelectField = SelectField;
export default Form;
